
import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from '../images/logo.png'
import wp from '../images/wp.png'
import tg from '../images/telegram.png'
import contact_us from '../images/contact_us.png'
import { Link } from 'react-router-dom'
import yt from '../images/youtube.png'
import callicon from '../images/callicon.png'
function Support() {
  const ludoname = "Badshah Ludo";

  const [users, setUsers] = useState([]);
  const [Whatsa, setWhatsa] = useState([]);
  useEffect(
    () => {
      loaduser();
    }, []
  );
  const loaduser = async () => {


    var data = '';

    const token = localStorage.getItem("token_store_vplay");
    const userid = localStorage.getItem("userid");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_ADMIN}/api/header.php?user_id=` + userid,
      user_id: userid,
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes[0].contactNumber);
        // setWhatsa("//api.whatsapp.com/send?phone=" + objectRes[0].contactNumber + "&text=");
        setWhatsa("//api.whatsapp.com/send?phone=9660056658&text=Hello");

      })
      .catch(function (error) {
        console.log(error);
      });

  }
  return (
    <>
      <div className="leftContainer">
        <div className="container">
          <div className="margintop-50">
            {/* <img src={contact_us} /> */}
         
          {/* <h3 className="text-center games-section-title">Contact us at below platforms.</h3> */}
          <h3 className="contactheading">Contact Us At Below Platforms.</h3>

          <div className="row">

            {/* <div className="col-md-6 text-center p-3 h2">

              <a href="" target="_blank">
                <img className="widthsocial" src={tg} alt="" />
                <p style={{ fontSize: '13px', marginTop: '5px' }}>{ludoname} Telegram</p>
              </a>
            </div> */}
            {/* <div className="col-md-12 text-center p-3 h2">
              <a href={Whatsa} className="linkedin-icon" title target="_blank">
                <img className="widthsocial" src={wp} alt="" />
                <span className="footer-text-bold d-block khelchampian">khelChampion Whatsapp</span>
              </a>
            </div> */}
            {/* <div className="col-md-6 col-lg-4 text-center h2 col-6">
                <div className="boxsupport">
                  <a href="" target="_blank" className="d-block">
                    <div className="imgsocial">
                      <img className="imagesocial" src={mailicons} alt="" />
                    </div>
                    <p style={{ fontSize: '13px', marginTop: '5px' }}>help@rkadda.com</p>
                  </a>
                </div>
              </div> */}
            <div className="col-md-4 col-lg-4 text-center h2 col-6">
              <div className="boxsupport">
                <Link to="#" target="_blank" className="d-block">
                  <div className="imgsocial">
                    <img className="imagesocial" src={yt} alt="" />
                  </div>
                  <p style={{ fontSize: '13px', marginTop: '5px' }}>Youtube</p>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 text-center h2 col-6">
              <div className="boxsupport">
                <Link to="#" target="_blank" className="d-block">
                  <div className="imgsocial">
                    <img className="imagesocial" src={callicon} alt="" />
                  </div>
                  <p style={{ fontSize: '13px', marginTop: '5px' }}>+91-9660056658</p>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 text-center h2 col-6">
              <div className="boxsupport">
                <Link to="//api.whatsapp.com/send?phone=9660056658&text=" className="linkedin-icon d-block" title target="_blank">
                  <div className="imgsocial">
                    <img className="imagesocial" src={wp} alt="" />
                  </div>
                  <p style={{ fontSize: '13px', marginTop: '5px' }}>Whatsapp</p>
                </Link>
              </div>
            </div>

          </div>
       
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="h5 footer-text-bold fw-bold">(Support)</p>
            <Link to="tel:" className="footer-text-bold text-primary">{users}</Link>
          </div>
          <div className="supportimage">
            <img className="iconsupport" src={logo} alt="" />

          </div>

          <p className="text-center text-dark">Support timing is from 10:00 AM to 06:00 PM
            <span className="d-block">(Monday to Saturday)</span>
          </p>
          <center>
            <Link>
              Whatsapp @ 9660056658
            </Link>
          </center>
        </div>
        <div className="divider-y" />
        <div className="rightContainer">
          <div className="rcBanner flex-center">
            <picture className="rcBanner-img-container">
              <img src="https://oneentertainmentclub.com/frontend/images/vplaylogo1.png" alt="" />
            </picture>
            <div className="rcBanner-text">{ludoname} <span className="rcBanner-text-bold">Win Real Cash!</span></div>
            <div className="rcBanner-footer">For best experience, open&nbsp;<a href="#!" style={{ color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none' }}>vplay.bet</a>&nbsp;on&nbsp;<img src="https://oneentertainmentclub.com/frontend/images/global-chrome.png" alt="" />&nbsp;chrome mobile</div>
          </div>
        </div>
      </div>
      </div>
    </div >
  
    </>
  );
}

export default Support;