

import React, { useEffect, useState } from "react";
import axios from "axios";

import earn from '../images/referral-user-welcome.png';
import rup from '../images/global-rupeeIcon.png';
import friend from '../images/referral-signup-bonus-new.png';
import Suppose from '../images/banner_illsutration.png';
import wp from '../images/wp.png'
import tg from '../images/telegram.png'
import tw from '../images/twitter.png'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const Referearn = () => {

  const [users, setUsers] = useState([]);
  const [TotalReffer, setTotalReffer] = useState([]);
  const [link, linkDta] = useState([]);
  const [linkwhatsapp, linkwhatsappDta] = useState([]);
  const [linktale, linktaleDta] = useState([]);
  const [RefferCommi, setRefferCommi] = useState([]);
  const [copySuccess, setCopySuccess] = useState(null);
  useEffect(
    () => {
      loaduser();
      // updateUsername();

    }, [true]
  );
  useEffect(
    () => {
      multiple_auth_chk();
    }, []
  );
  const multiple_auth_chk = async () => {


    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/multiple-auth-chk`,
      headers: {
        'x-access-token': token
      },
      data: { ipaddress: ipaddress }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        // var totlaipp = objectRes.data[0]['ip'];
        // // alert(totlaipp);
        // if (totlaipp != ipaddress) {
        //   localStorage.setItem('token_store_vplay');
        //   localStorage.setItem('userid');
        //   window.location.href = '/login';
        // }
        // console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
    // if (!token) {
    //   window.location.href = '/login';
    //   // exist();
    // }
  }
  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-profile`,
      // let config = axios.post(process.env.REACT_APP_API_URL + "/get-profile", {
      headers: {
        'x-access-token': token
      },
    };

    axios(config)
      .then(function (response) {
   
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res); 
        console.warn(objectRes.data[0].referral_code)
        setUsers(objectRes.data[0]);     
        setTotalReffer(objectRes.totalrefferuser_data[0]['totalrefferedplayer']);        
        linkDta('https://badshahludo.com/login?reffercode=' + objectRes.data[0].referral_code);        
        linkwhatsappDta('//api.whatsapp.com/send?text=You get 2% Commission on your referral winnings. https://badshahludo.com/login?reffercode=' + objectRes.data[0].referral_code);
        linktaleDta(' tg://msg?text=https://badshahludo.com/login?reffercode=' + objectRes.data[0].referral_code);
        sessionStorage.setUsers("req_id", users.headers);
        setRefferCommi(objectRes.genrealseting[0].reffer_comisssion);

      })
      .catch(function (error) {
        console.log(error);
      });

    //   axios.post(`http://103.129.97.243:8080/api/users/get-profile`, {
    //     headers: {
    //       'x-access-token':token
    //     },
    // })
    //     .then(res => {
    //         // const persons = res.data.data;
    //         console.warn(res);
    //     })
  }

  const copyToClipBoard = async (copyMe) => {
    // Alert to show the copied text
    // alert(copyMe);
  
    // Create a Swal instance with React content
    const MySwal = withReactContent(Swal);
  
    // Show a success message
    await MySwal.fire({
      title: <strong>Refer Code Copied!</strong>,
      icon: 'success',
      timer: 3000,
    });
  
    // Copy text to clipboard
    try {
      await navigator.clipboard.writeText(copyMe);
      console.log('Copied!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };
  return (
    <>
      <div className="leftContainer">
        <div className="main-area style={{ paddingTop: '60px' }}">
          <div className="center-xy">
            <picture className="mt-1"><img width="226px" src={earn} alt="" /></picture>
            <div className="mb-1">
              <div className="font-15">Earn now unlimited <span role="img" aria-label="party-face">🥳</span></div>
              <div className="d-flex justify-content-center">Refer your friends now!</div>
              <div className="text-bold mt-3 text-center">Your Refer Code: {users.referral_code}<img className="ml-2" width="20px" src="/images/icon-edit.jpg" alt="" /></div>
              <div className="d-flex justify-content-center">Total Refers:&nbsp;<b>{TotalReffer}</b></div>
              <div className="d-flex justify-content-center">Total Earnings:&nbsp;<b><img src={rup} alt="" width="20px;" />₹{users.redeem_amount}</b></div>
            </div>
          </div>
          <div className="divider-x" />
          <div className="mx-3 my-3">
            <div className="font-11">Refer &amp; Earn Rules</div>
            <div className="d-flex align-items-center m-3">
              <picture><img width="82px" src={friend} alt="" /></picture>
              <div className="font-9 mx-3" style={{ width: '63%' }}>
                <div>When your friend signs up on One Entertainment Club from your referral link,</div>
                <div className="font-8 c-green mt-2">You get <strong> {(RefferCommi === null || RefferCommi === 'undefined')? RefferCommi : "2"}% Commission</strong> on your <strong>referral's winnings.</strong></div>
              </div>
            </div>
            <div className="d-flex align-items-center m-3">
              <picture><img width="82px" src={Suppose} alt="" /></picture>
              <div className="font-9 mx-3" style={{ width: '63%' }}>
                <div>Suppose your referral plays a battle for ₹10000 Cash,</div>
                <div className="font-8 c-green mt-2">You get <strong>₹200 Cash</strong> <strong /></div>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: '150px' }} />
          <div className="refer-footer refer_button_custum flex-column justify-content-center" style={{ height: '150px' }}>
            {/* <div className="d-flex text-cemter font-11">SHARE IN LISTED CHANNELS:</div> */}
            <div className="d-flex text-cemter font-11">SHARE IN :</div>
        
            <div className="d-flex mt-1">
              <a href={linkwhatsapp} className="bg-green refer-button cxy w-100" title target="_blank"><img height="25px" width="25px" src={wp} alt="" />WhatsApp</a>

              <a href={linktale} target="_blank" className="bg-info refer-button cxy w-100 flex-column ml-2">
                <img height="20px" width="20px" src={tg} alt="" />
                <span>Telegram</span></a>
              <button className="bg-primary refer-button cxy w-100 flex-column ml-2">
                <img height="20px" width="20px" src={tw} alt="" />
              </button>
            </div>
            <div className="d-flex mt-2">
              <input type="text" defaultValue={link} id="myInput" style={{ display: 'none' }} />

              <button className="bg-secondary  refer-button cxy ml-2" onClick={(e) => copyToClipBoard(link)} key={users.referral_code}> <span className="mr-2" ><FileCopyIcon />Copy Refer Link </span>
                <i className="fa fa-copy" />
              </button>
            </div>
         
          </div>
        </div>
      </div>
    </>
  );
}

export default Referearn;