import React, { useEffect, useState } from "react";
import Gameshis from "../images/kb_ludo_classic.jpeg";
import rup from "../images/global-rupeeIcon.png";
import nogame from "../images/nogamehistory.png";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const Gameshistory = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    loaduser();
    multiple_auth_chk();
    // alert('game')
  }, []);
  const multiple_auth_chk = async () => {
    var data = "";
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/multiple-auth-chk`,
      headers: {
        "x-access-token": token,
      },
      data: { ipaddress: ipaddress },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]["ip"];
        // alert(totlaipp);
        // if (totlaipp != ipaddress) {
        //   localStorage.setItem("token_store_vplay", " ");
        //   localStorage.setItem("userid", " ");
        //   window.location.href = "/login";
        // }
        // console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
    // if (!token) {
    //   window.location.href = "/login";
    //   // exist();
    // }
  };
  const loaduser = async () => {
    setLoading(true);
    const token = localStorage.getItem("token_store_vplay");

    var data = "";

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-game-history`,
      headers: {
        "x-access-token": token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.warn(objectRes.data);
        // alert(objectRes.data)
        setUsers(objectRes.data);
        console.log(users[2].id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {/* <Menubar /> */}
      <div className="leftContainer">
        <div>
          {users > '' ?
            users &&
            users.map((user) => (
              <>
                <div
                  className="w-100  d-flex align-items-center list-item "
                  style={{ paddingTop: "10px" }}
                >
                  <div className="center-xy list-date mx-2">
                    <div>
                      {user.day}
                      {user.month}
                    </div>
                    <small>{user.paying_time}</small>
                  </div>
                  <div className="list-divider-y" />
                  <div className="mx-3 d-flex list-body">
                    <div className="d-flex align-items-center">
                      <picture className="mr-2">
                        <img
                          height="32px"
                          width="32px"
                          src={Gameshis}
                          alt=""
                          style={{ borderRadius: "5px" }}
                        />
                      </picture>
                    </div>
                    <div className="d-flex flex-column font-8">
                      <div>
                        {user.match_result == "win"
                          ? "win"
                          : user.match_result == "lost"
                            ? "lost"
                            : "cancel"}{" "}
                        against <b>{user.GamePartnerId}</b>.
                      </div>
                      <div className="games-section-headline">
                        Battle ID: {user.battle_id}
                      </div>
                    </div>
                  </div>
                  <div className="right-0 d-flex align-items-end pr-3 flex-column">
                    <div className="d-flex float-right font-8">
                      {user.match_result == "win" ? (
                        <div className="text-success">(+)</div>
                      ) : user.match_result == "cancel" ? (
                        <div className="text-warning">(=)</div>
                      ) : (
                        <div className="text-danger">(-)</div>
                      )}

                      <picture className="ml-1 mb-1">
                        <img height="21px" width="21px" src={rup} alt="" />
                      </picture>
                      <span className="pl-1">
                        {user.match_result == "win"
                          ? user.winning_amount - user.lossing_amount
                          : user.lossing_amount}
                      </span>
                    </div>
                    <div
                      className="games-section-headline"
                      style={{ fontSize: "0.6em" }}
                    >
                      Closing Balance: {user.closing_balance}{" "}
                    </div>
                  </div>
                </div>
              </>

            ))
            :
            <div className="nodata"><img width="70%" className="" src={nogame} alt="" />
              <h2 className="text-center" style={{ color: "#feb946" }}>No Game History</h2>
              <p className="text-center" style={{ color: "#000" }}>You have no game history yet.</p>
            </div>
          }

          {/* {
              !isLoading ? <> {users.length ? <p>somthing</p> : <p>data not found</p>} </> : <Spinner />
            } */}
        </div>
      </div>
    </>
  );
};

export default Gameshistory;
