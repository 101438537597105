import { React, useState, useEffect } from "react";
import axios from "axios";
import logos from "../images/logo.png";
import logo1 from "../images/ludoclassi.jpg";
import snakeandladder from "../images/snakeandladder.jpg";
import logo4 from "../images/ludoclassicmanual.jpg";

import logo2 from "../images/snakeandladder.jpg";
import logo3 from "../images/global-battleIconWhiteStroke.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Outlet, Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import Aboutus from "../routes/Aboutus";
import Contactus from "../routes/Contactus";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Spinner } from "react-bootstrap";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
function Deshbord() {
  const [genralsettings, setGenralSetting] = useState([]);
  const [Game1, setGame1] = useState([]);
  const [Game2, setGame2] = useState([]);
  const [Game3, setGame3] = useState([]);
  const [Game4, setGame4] = useState([]);
  const [Game5, setGame5] = useState([]);
  const ludoname = "Badshah Ludo";

  useEffect(() => {
    genralsetting();
  }, [true]);
  const genralsetting = async () => {
    // setLoading(true);
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-genral-setting`,
      headers: {
        "x-access-token": token,
      },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes);
        if (objectRes.status === 1) {
          setGenralSetting(objectRes.data[0]);
          // console.warn(objectRes.data[0])
          setGame1(objectRes.game1);
          setGame2(objectRes.game2);
          setGame3(objectRes.game3);
          setGame4(objectRes.game4);
          setGame5(objectRes.game5);
        } else if (objectRes.status === 0) {
          const MySwal = withReactContent(Swal);
          MySwal.fire({
            title: "Service Not Avilabile",
            icon: "error",
          });
          // toast.info("Please wait!");
        }
      })
      .catch(function (error) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: "Service Not Avilabile",
          icon: "error",
        });
      })
      .finally(() => { });
  };
  // const handleClick = () => {
  //   const MySwal = withReactContent(Swal);
  //   MySwal.fire({
  //     title: "Service Not Avilabile",
  //     icon: "error",
  //   });
  // };
  const handleClick = () => {
    // const MySwal = withReactContent(Swal);
    // MySwal.fire({
    //   title: "Please wait!!",
    //   icon: "error",
    // });
    toast.error("Please wait!");
  };
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(true);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        setDeferredPrompt(event);
      });
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          setShowInstallButton(false);
        } else {
          console.log('User dismissed the A2HS prompt');
        }

        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      <div class="leftContainer">
        <div className="main-area">
          <div className="collapseCard-container">
            <div className="btninstall">
              {showInstallButton && <button style={{ background: '#ae2d08' }} className="btn-install  otp_login submit_data" onClick={handleInstallClick}>Install Web Application</button>}

            </div>
            {/* <span>
              {" "}
              किसी भी यूजर्स का पेमेंट ID से कट हुआ हैं तो यूजर परेशान ना हो सब
              का पेमेंट रिफंड हो जायेगा with in 24 Hrs में।🙏🏼🙏🏼
            </span> */}
            <Marquee>{genralsettings.top_heading}</Marquee>
            <div className="collapseCard">
              {/* <h5>{ludoname}</h5> */}
            </div>
          </div>
        </div>

        <section className="games-section p-3">
          <div className="d-flex align-items-center games-section-title">
            Our Games
          </div>
          <div className="games-section-headline mt-2 mb-1">
            <div className="games-window">
              <div className="gameCard-container">
                {Game1 == 1 ? (
                  <Link className="gameCard" to="/Ludo-Classic">
                    <span className="blink d-block text-left">
                      ◉ LIVE
                    </span>
                    <picture className="gameCard-image">
                      <img width="100%" src={logo1} alt="" />
                    </picture>
                    {/* <div className="gameCard-title">
                      Ludo Classic <br></br> (Manual Room code){" "}
                    </div> */}
                    <picture className="gameCard-icon">
                      <img src={logo3} alt="" />
                    </picture>
                  </Link>
                ) : (
                  <Link className="gameCard" to="/" onClick={handleClick}>
                    <picture className="gameCard-image">
                      <img width="100%" src={logo1} alt="" />
                    </picture>
                    {/* <div className="gameCard-title">
                      {" "}
                      Ludo Classic <br></br> (Manual Room code){" "}
                    </div> */}
                    <picture className="gameCard-icon">
                      <img src={logo3} alt="" />
                    </picture>
                    <Spinner
                      animation="border"
                      style={{
                        marginLeft: '5px',
                        width: '25px',
                        height: '25px',
                        marginTop: "2px",
                        top: "50%",
                        left: "36%",
                        color: "black"
                      }}
                      className="position-absolute"
                    >
                    </Spinner>
                  </Link>
                )}
              </div>
              <div className="gameCard-container">
                {Game5 == 1 ? (
                  <Link className="gameCard" to="/Ludo-Classic-Second">
                    <span className="blink d-block text-left">
                      ◉ LIVE
                    </span>
                    <picture className="gameCard-image">
                      <img width="100%" src={logo4} alt="" />
                    </picture>
                    {/* <div className="gameCard-title">
                      Ludo Classic popular <br></br> (Auto Room code)
                    </div> */}
                    <picture className="gameCard-icon">
                      <img src={logo3} alt="" />
                    </picture>

                  </Link>
                ) : (
                  <Link className="gameCard" to="/" onClick={handleClick}>
                    {/* <Link className="gameCard" to="/Ludo-Classic" > */}
                    <picture className="gameCard-image">
                      <img width="100%" src={logo4} alt="" />
                    </picture>
                    {/* <div className="gameCard-title">
                      Ludo Classic popular <br></br>(Auto Room code){" "}
                    </div> */}
                    <picture className="gameCard-icon">
                      <img src={logo3} alt="" />
                    </picture>
                    <Spinner
                      animation="border"
                      style={{
                        marginLeft: '5px',
                        width: '25px',
                        height: '25px',
                        marginTop: "2px",
                        top: "50%",
                        left: "36%",
                        color: "black"
                      }}
                      className="position-absolute"
                    >
                    </Spinner>
                  </Link>
                )}
              </div>
              <div className="gameCard-container ">
                {Game4 == 1 ? (
                  <Link className="gameCard " to="/Snake-and-Ladder-sidha">
                    <span className="blink d-block text-left">
                      ◉ LIVE
                    </span>
                    <picture className="gameCard-image">
                      <img width="100%" src={snakeandladder} alt="" />
                    </picture>

                    <picture className="gameCard-icon">
                      <img src={logo3} alt="" />
                    </picture>
                  </Link>
                ) : (
                  <Link className="gameCard" to="/" onClick={handleClick}>
                    <picture className="gameCard-image">
                      <img width="100%" src={snakeandladder} alt="" />
                    </picture>

                    <picture className="gameCard-icon">
                      <img src={logo3} alt="" />
                    </picture>
                    <Spinner
                      animation="border"
                      style={{
                        marginLeft: '5px',
                        width: '25px',
                        height: '25px',
                        marginTop: "2px",
                        top: "50%",
                        left: "36%",
                        color: "black"
                      }}
                      className="position-absolute"
                    >
                    </Spinner>
                  </Link>
                )}
              </div>
              {/* <div className="d-flex w-100 justify-content-between">
                <div className="btnlink">
                  <Link className="aboutus" to="/Aboutus">
                    About Us
                  </Link>
                </div>
                <div className="btnlink">
                  <Link className="aboutus" to="/support">
                    Contact Us
                  </Link>
                </div>
              </div> */}
              {/* <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game3 == 1 ? 
                                <Link className="gameCard" to="/Snake-and-Ladder-sidha">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder </div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo2} alt="" />
                                </picture>
                                <div className="gameCard-title">Snake and Ladder </div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game4 == 1 ? 
                                <Link className="gameCard" to="/Snake-and-Ladder-ulta">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder </div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo2} alt="" />
                                </picture>
                                <div className="gameCard-title">Snake and Ladder </div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div> */}
            </div>
          </div>
        </section>

        <section className="footer">
          <div className="footer-divider" />
          <div className="games-section game_footer_image p-3">
            <div>
              <picture className>
                <img src={logos} alt="" width="80px" height="80px" />
              </picture>
              <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}> . Terms, Privacy,
                Support</span>
              <span className="d-block thegameison"><strong>The Game is
                ON</strong></span>
            </div>

            <div className="row footer-links">
              <Link className="col-6" to="/Aboutus">
                About Us
              </Link>
              <Link className="col-6" to="/support">
                Contact Us
              </Link>
              <Link className="col-6" to="/term-condition">
                Terms &amp; Condition
              </Link>
              <Link className="col-6" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="col-6" to="/refund-policy">
                Refund/Cancellation Policy
              </Link>
              <Link className="col-6" to="/contact-us">
                Contact Us
              </Link>
              <Link className="col-6" to="/responsible-gaming">
                Responsible Gaming
              </Link>
            </div>
          </div>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              {/* <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"

                                >
                                    <Typography>

                                        <a className="px-3 py-4 d-block" href="#!" style={{ textDecoration: 'none' }}>
                                            <picture className>

                                                <img src={logos} alt="" width="100px" hight="20px" />
                                            </picture>
                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}> . Terms, Privacy,
                                                Support</span><br />

                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}><strong>The Game is
                                                ON</strong></span>
                                        </a>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row footer-links">
                                            <Link className="col-6" to="/term-condition">Terms &amp; Condition</Link>
                                            <Link className="col-6" to="/privacy-policy">Privacy Policy</Link>
                                            <Link className="col-6" to="/refund-policy">Refund/Cancellation Policy</Link>
                                            <Link className="col-6" to="/contact-us">Contact Us</Link>
                                            <Link className="col-6" to="/responsible-gaming">Responsible Gaming</Link>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion> */}
            </div>
            <div className="footer-divider" />
            <div className="px-3 py-4">
              <div className="about_heading">
                <h4>About Us</h4>
              </div>
              <br />
              <div className="dashboard_paragraph">
                {ludoname} is a real-money gaming product owned
                and operated by Gamicbond.pvt.ltd {"("} "{ludoname}" or
                "We" or "Us" or "Our" {")"}..
              </div>
              <br />
              <div className="about_heading">
                <h4>Our Business &amp; Products</h4>
              </div>
              <br />
              <div className="dashboard_paragraph">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="dashboard_paragraph">
                {ludoname} is a skill-based real-money gaming
                platform accessible only for our users in India. It is
                accessible on{" "}
                <Link to="/">https://Badshahludo.com.</Link> On {ludoname}, users can compete for real cash in
                Tournaments and Battles. They can encash their winnings via
                popular options such as Paytm Wallet, Amazon Pay, Bank Transfer,
                Mobile Recharges etc.{" "}
              </div>
              <br />
              <div className="about_heading">
                <h4>Our Games</h4>
              </div>
              <br />
              <div className="dashboard_paragraph">
                {ludoname} has a wide-variety of high-quality,
                premium HTML5 games. Our games are especially compressed and
                optimised to work on low-end devices, uncommon browsers, and
                patchy internet speeds.{" "}
              </div>
              <br />
              <div className="dashboard_paragraph">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends.
                If you have any suggestions around new games that we should add
                or if you are a game developer yourself and want to work with
                us, don't hesitate to drop in a line at
                Badshahludogame@outlook.com !
              </div>
            </div>
          </div>
        </section>

        {/* <Rightsidebar/> */}
      </div>

      <Outlet />
      <ToastContainer />
    </>
  );
}

export default Deshbord;
