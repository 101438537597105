import React, { useEffect, useState, useRef } from "react";

import rup from '../images/global-rupeeIcon.png';
import global from '../images/global-grey-iButton.png';
import player from '../images/Avatar2.png';
import versus from '../images/versus.png';
import battleIcon from '../images/global-purple-battleIcon.png'
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import audio from '../images/battle_accepted.mp3';

import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import io from "socket.io-client";
const token = localStorage.getItem('token_store_vplay');
const LudoPopular = () => {
    const audioRef = useRef(null);

    const playAudio = () => {
        audioRef.current.play();
    };
    // =====Bat Create Api=====
    const [validationErrors, setValidationErrors] = useState({});
    const userId = localStorage.getItem("userid");
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [gamebat, setGamebat] = useState(
        {
            game_url: "Ludo-Popular",
            amount: '',
            label: ""
        }
    )
    const [gameid, setgameid] = useState({

        "game_id": "2"
    })

    const { amount, label } = gamebat;
    const oninputchange = (e) => {
        setGamebat({ ...gamebat, [e.target.name]: e.target.value });
    }

    const onSubmit = async (e) => {
        // alert('ppppp');
        const token = localStorage.getItem("token_store_vplay");
        try {
            // Reset validation errors
            setValidationErrors({});

            // Check if any of the required fields are empty
            const errors = {};
            // if (!label) {
            //     errors.label = "Label code is required";
            // }
            if (!amount) {
                errors.amount = "Amount is required";
            }

            if (Object.keys(errors).length > 0) {
                setValidationErrors(errors);
                return;
            }

            if (
                amount === null
            ) {
                return;
            }
            $('#set_submit').attr('disabled', 'true');
            console.log(gamebat);
            e.preventDefault();

            var config = {
                method: 'post',
                gameType: 'Ludo Ulta',
                url: `${process.env.REACT_APP_API_URL_NODE}/api/users/game-bat-store`,
                headers: {
                    'x-access-token': token
                },
                data: gamebat
            };

            axios(config).then((response) => {
                $('#set_submit').attr('disabled', 'false');
                if (response.data.message == 'Successfully') {
                    // setGamebat({ amount: '', label: '' });
                    const MySwal = withReactContent(Swal)
                    MySwal.fire({
                        title: <strong>Game-Bat-Store Successfully!</strong>,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    const MySwal = withReactContent(Swal)
                    MySwal.fire({
                        title: response.data.message,
                        icon: 'error'
                    })
                }
            });
        } catch (error) {
            console.error("Error Game List:", error);

        }
    }

    const onInputChange = (e) => {
        var ledata = e.target.value;
        var dd = ledata.length;
        if (dd > 20) {
            $('.clrcon').val('');
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: <strong>Please Enter last 20 words</strong>,
                icon: 'error',
                timer: 1500
            })
        }
        setGamebat({ ...gamebat, [e.target.name]: e.target.value });
    }

    // ======gameOpanApi======


    // useEffect(
    //     () => {
    //         loaduser();
    //         //   alert('game')

    //     }, []
    // );


    const [users, setUsers] = useState([]);

    // useEffect(() => {

    //     const socket = io('http://13.200.28.44:8081', {
    //         auth: { token },
    //     });
    //     const interval = setInterval(() => {
    //         loaduser();
    //     }, 1000);

    //     socket.on('connect', () => {
    //         loaduser();
    //     });

    //     socket.on('user_data', (data) => {

    //         console.log(data);
    //     });

    //     socket.on('error', (error) => {
    //         console.error('Socket error:', error);
    //     });

    //     // Clean up socket connection when the component unmounts
    //     return () => {
    //         socket.disconnect();
    //         clearInterval(interval);
    //     };
    // }, [users]);

    const loaduser = async () => {
        const token = localStorage.getItem("token_store_vplay");
        // console.log(token);
        var data = '';
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/gameopen`, gameid,
            headers: {
                'x-access-token': token
            },
            data: gameid,
        };
        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                console.log(objectRes.data);
                setUsers(objectRes.data);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // ======gameRunningApi======

    const [game, setGame] = useState([]);
    const [gameself, setGameSelf] = useState([]);
    useEffect(
        () => {
            GameRunning();
            //   alert('game')
        }, []
    );


    const GameRunning = async () => {
        const token = localStorage.getItem("token_store_vplay");

        var data = '';
        // console.log(data)
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/gameRunning`,
            headers: {
                'x-access-token': token
            },
            data: gameid,
        };

        axios(config)
            // console.log(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                console.log(objectRes.data);
                setGame(objectRes.data);
                setGameSelf(objectRes.data1);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // Api lobby-delete----------

    function Delete(id) {
        const token = localStorage.getItem("token_store_vplay");
        var data = {
            id: id
        };

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/lobby-delete`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        axios(config)
            .then(function (response) {
                // alert('Resource deleted successfully');
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // Api Play Users------

    const Playuser = async (id) => {
        const token = localStorage.getItem("token_store_vplay");
        var data = {
            id: id
        };
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/lobby-send-request`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        try {
            const response = await axios(config);
            if (response.data.status == 0) {
                const MySwal = withReactContent(Swal)
                MySwal.fire({
                    title: response.data.message,
                    icon: 'error'
                })
            }
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };
    useEffect(
        () => {
            multiple_auth_chk();
        }, []
    );
    const multiple_auth_chk = async () => {


        var data = '';
        const ipaddress = localStorage.getItem("ipaddress");
        const token = localStorage.getItem("token_store_vplay");
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/multiple-auth-chk`,
            headers: {
                'x-access-token': token
            },
            data: { ipaddress: ipaddress }
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                // console.warn(objectRes.data[0]['total']);
                var totlaipp = objectRes.data[0]['ip'];
                // alert(totlaipp);
                // if (totlaipp != ipaddress) {
                //     localStorage.setItem('token_store_vplay', ' ');
                //     localStorage.setItem('userid', ' ');
                //     window.location.href = '/login';
                // }
                // console.warn(totlaipp);
                // setUsers(objectRes);
            })
            .catch(function (error) {
                console.log(error);
            });
        // if (!token) {
        //     window.location.href = '/login';
        //     // exist();
        // }
    }
    const Startuser = async (id) => {
        // alert(id);
        const token = localStorage.getItem("token_store_vplay");
        var data = {
            id: id
        };
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/lobby-start`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        try {
            const response = await axios(config);
            // window.location.href = `/Ludo-Ulta/Roomcode?id=${id}`;
            window.location.href = `/Battlechat?id=${id}`;
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const Rejecttuser = async (id) => {
        const token = localStorage.getItem('token_store_vplay');
        var data = {
            id: id,
        };
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/lobby-reject-request`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        };

        try {
            const response = await axios(config);

            if (response.data.message == "success") {
                setShowDeleteButton(true);
            } else {
                setShowDeleteButton(false);
            }
        } catch (error) {
            console.log(error);
            setShowDeleteButton(false);
        }
    };





    const Startuserjoiner = async (id) => {
        const token = localStorage.getItem("token_store_vplay");
        var data = {
            id: id
        };
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/lobby-start-joiner`,
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        try {
            const response = await axios(config);
            //  window.location.href = `/Ludo-Ulta/Roomcode?id=${id}`;
            window.location.href = `/Battlechat?id=${id}`;
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const handleClick = (id) => {
        // alert(id);
        // window.location.href = `/Ludo-Ulta/Roomcode?id=${id}`;
        window.location.href = `/Battlechat?id=${id}`;
    };



    return (
        <>
            <div className="leftContainer">
                <div className="container">
                    <div>
                        <div className="main-area" />
                        <span className="cxy battle-input-header">Create a Battle!</span>
                        <div className=" d-flex justify-content-center my-2">
                            <form onSubmit={onSubmit}>
                                <div className="d-flex">
                                    <div>
                                        <div
                                            className=" set_amount"
                                            style={{ paddingRight: 0, marginBottom: "15px" }}
                                        >
                                            <input type="hidden" name="game_url" value={"Ludo Ulta"} />
                                            <input type="hidden" name="_token" defaultValue="Gd9E2uC5KGeNReVLl0CZdcU1i7n4T8wTX6JsQ84R" />
                                            <div>
                                                <input
                                                    className="form-control clrcon"
                                                    type="tel"
                                                    name="amount"
                                                    value={gamebat.amount}
                                                    onChange={onInputChange}
                                                    placeholder="Amount"
                                                />
                                                {validationErrors.amount && (
                                                    <p>{validationErrors.amount}</p>
                                                )}
                                                <div className="error" />
                                            </div>
                                        </div>
                                        <div className=" set_amount" style={{ paddingRight: "0px" }}>
                                            {/* <input
                                                className="form-control clrcon"
                                                type="text"
                                                name="label"
                                                value={gamebat.label}
                                                onChange={onInputChange}
                                                placeholder="Ex.Level 10+"
                                                min={5}
                                                max={11}
                                                style={{ float: "left" }}
                                            /> */}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="" style={{ marginBottom: "15px" }}>
                                            <button
                                                className="bg-success  set_btn btn text-white cxy position-static"
                                                type="button"
                                                onClick={onSubmit}
                                            >
                                                Set
                                            </button>
                                        </div>
                                        <div className="">
                                            {/* <Link to="/info-conditions">
                                                <img
                                                    className="ml-2"
                                                    src={global}
                                                    alt=""
                                                    style={{ paddingLeft: "21px" }}
                                                />
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <button class="bg-green playButton cxy position-static" type="submit" style="margin: 3px; float:right">Set</button> */}
                            </form>
                        </div>
                    </div>
                </div>

                <div className="divider-x" />
                <div className="px-4 py-3">
                    <div className="mb-2">
                        <img src={battleIcon} width="20px" alt="" />
                        <span className="ml-2 fw-bold">Open Battles</span>
                        <Link to="/rules"><span className="games-section-headline text-uppercase position-absolute font-weight-bold" style={{ right: '1.5rem' }}>Rules
                            <img className="ml-2" src={global} alt="" /></span></Link>

                    </div>
                    {users && users.map((user) => (
                        <>
                            <div id="openBattle">
                                <div id="6345971c71f80dea71bf71cf" className="betCard mt-1">
                                    <span className="betCard-title pl-3 d-flex align-items-center text-uppercase justify-content-between">CHALLENGE FROM {user.vplay_id}
                                        {/* <span className="ml-1" style={{ color: 'brown' }}></span> */}

                                        {user.creator_id == userId && user.request_status == 0 ? (
                                            <button className="m-1 mb-1 ml-auto btn-danger btn-sm" style={{ padding: '2px 2px 2px 2px' }}>
                                                <a href="#" style={{ fontSize: '9px', color: 'white', fontWeight: 800 }} onClick={() => Delete(user.battleprimaryid)}> DELETE</a>
                                            </button>
                                        ) : null}


                                        {user.creator_id == userId && user.request_status == 2 ? (
                                            <div className="req_cancle122">
                                                <button className="mb-1  btn-success btn-sm" style={{ fontSize: '12px', fontWeight: 800, padding: "6px 16px", marginRight: "10px" }} onClick={() => Startuser(user.battleprimaryid)}>
                                                    Start
                                                </button>

                                                <button className="m-1 mb-1 ml-auto btn-danger btn-sm" style={{}}>
                                                    <a href="#" style={{ fontSize: '12px', color: 'white', fontWeight: 800, padding: "6px 2px" }} onClick={() => Rejecttuser(user.battleprimaryid)}> Reject</a>
                                                </button>
                                            </div>
                                        ) : null}


                                        {/* {showDeleteButton && user.creator_id == userId && user.request_status == 0 ? (
                                                <button
                                                className="m-1 mb-1 ml-auto btn-danger btn-sm"
                                                style={{ padding: '2px 2px 2px 2px' }}
                                                >
                                                <a
                                                    href="#"
                                                    style={{ fontSize: '9px', color: 'white', fontWeight: 800 }}
                                                    onClick={() => Delete(user.id)}
                                                >
                                                    DELETE
                                                </a>
                                                </button>
                                            ) : null} */}

                                        {user.creator_id == userId && user.request_status == 1 ? (
                                            <div className="button_view">
                                                <button className="m-1 mb-1 ml-auto btn-primary btn-sm">
                                                    {/* <a href="/Ludo Ulta/Roomcode" style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} >Viewssss</a> */}
                                                    <a style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => handleClick(user.battleprimaryid)}>View</a>
                                                </button>
                                            </div>
                                        ) : null}
                                    </span>

                                    <div className="d-flex justify-content-between pl-3">
                                        <div className="d-flex">
                                            <div className="pr-3 pb-1"><span className="betCard-subTitle">Entry</span>
                                                <div><img src={rup} width="21px" alt="" /><span className="betCard-amount">{user.amount}</span></div>
                                            </div>
                                            <div><span className="betCard-subTitle">Prize</span>
                                                <div><img src={rup} width="21px" alt="" /><span className="betCard-amount">{user.prize}</span></div>
                                            </div>
                                            <div style={{ marginTop: '4px', marginLeft: '15px', fontSize: '13px' }} align="center">
                                                {/* <span className="betCard-subTitle" style={{ fontSize: '10px' }}>Condition</span>
                                            <div>{user.label}</div> */}
                                            </div>
                                        </div>





                                        {user.creator_id == userId && user.request_status == 0 ? (
                                            <div className="text-center col-5 ml-auto mt-auto mb-auto"><div className="pl-2 text-center"><Spinner animation="grow" size="sm" /></div><div style={{ lineHeight: 1 }}><span className="Home_betCard_playerName__57U-C" style={{ fontSize: '13px' }}>Finding Player!</span></div>
                                            </div>
                                        ) : null}

                                        {userId != user.creator_id && user.request_status == 0 ? (
                                            <>
                                                <button className="m-1 mb-1 play ml-auto btn btn-secondary btn-sm" style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => {
                                                    Playuser(user.battleprimaryid);
                                                    playAudio();
                                                }}>
                                                    Play
                                                </button>
                                                <audio ref={audioRef}>
                                                    <source src={audio} type="audio/mpeg" />
                                                </audio>
                                            </>
                                            // <button className="m-1 mb-1 play ml-auto btn btn-secondary btn-sm" style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => Playuser(user.battleprimaryid)}>
                                            //     Play
                                            // </button>
                                        ) : null}


                                        {userId != user.creator_id && user.request_status == 1 && user.joiner_id == userId ? (
                                            <button className="start" style={{ fontSize: '12px', color: 'white', fontWeight: 800, width: "90px" }} onClick={() => Startuserjoiner(user.battleprimaryid)}>
                                                Start
                                            </button>
                                        ) : null}

                                        {userId != user.creator_id && user.request_status == 2 && user.joiner_id == userId ? (
                                            <div className="req_cancle">
                                                <button className="m-1 mb-1 ml-auto btn btn-secondary btn-sm request_cancel">
                                                    <a href="#" style={{ fontSize: '12px', color: 'white', fontWeight: 800 }}>request</a>
                                                </button>

                                                <button className="m-1 mb-1 ml-auto btn-danger btn-sm request_cancel">
                                                    <a href="#" style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => Rejecttuser(user.battleprimaryid)}> Cancel</a>
                                                </button>

                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                            <div id="myOpenBattles" />
                            {/*//Open Battle is here*/}
                            {/* < div id="openBattle" /> */}
                            <div className="timebeforedata" style={{ display: 'none' }}>
                            </div>
                        </>
                    ))}
                </div>

                <div className="px-4 py-3  position-relative">
                    <div className="mb-2">
                        <img src={battleIcon} width="20px" alt="" />
                        <span className="ml-2 fw-bold">Running Battles</span>
                    </div>
                    {gameself && gameself.map((user) => (
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed position-absolute" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" style={{ width: '10%', top: 0, right: '30px' }}>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    {/* {users && users.map((user) => (
                                            <> */}
                                    <div className="accordion-body">
                                        <div id="myRunningBattles" />
                                        <div id="runningBattle">

                                            {/* battle running*/}
                                            <div id="633c3b4e85a962efab2f95db" className="betCard mt-1">
                                                <div className="d-flex">
                                                    <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                                                        PLAYING FOR<img className="mx-1" src={rup} width="21px" alt="" />{user.amount}</span>
                                                    {user.creator_id == userId && user.is_running == 'yes' ? (
                                                        <span>
                                                            <button className="m-1 mb-1 ml-auto btn-primary btn-sm">
                                                                <a style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => handleClick(user.id)}>View</a>
                                                            </button>
                                                        </span>
                                                    ) : null}

                                                    {/* {user.joiner_id == userId && user.is_running == 'yes' ? ( */}
                                                    <span>
                                                        <button className="m-1 mb-1 ml-auto btn-primary btn-sm" >
                                                            <a style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => handleClick(user.id)}>View</a>
                                                        </button>
                                                    </span>
                                                    {/* ) : null} */}

                                                    <div className="betCard-title d-flex align-items-center text-uppercase"><span className="ml-auto mr-3">PRIZE<img className="mx-1" src={rup} width="21px" alt="" />{user.prize}</span></div>
                                                </div>
                                                <div className="py-1 row">
                                                    <div className="pr-3 text-center col-5">
                                                        <div className="pl-2"><img className="border-50" src={player} width="21px" height="21px" alt="" /></div>
                                                        <div style={{ lineHeight: 1 }}><span className="betCard-playerName">{user.label} </span></div>
                                                    </div>
                                                    <div className="pr-3 text-center col-2 cxy">
                                                        <div><img src={versus} width="21px" alt="" /></div>
                                                    </div>
                                                    <div className="text-center col-5">
                                                        <div className="pl-2"><img className="border-50" src={player} width="21px" height="21px" alt="" /></div>
                                                        <div style={{ lineHeight: 1 }}><span className="betCard-playerName">{user.battle_id}</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* </>
                                        ))} */}
                                </div>
                            </div>
                        </div>
                    ))}
                    {game && game.map((user) => (
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed position-absolute" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" style={{ width: '10%', top: 0, right: '30px' }}>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    {/* {users && users.map((user) => (
                                            <> */}
                                    <div className="accordion-body">
                                        <div id="myRunningBattles" />
                                        <div id="runningBattle">

                                            {/* battle running*/}
                                            <div id="633c3b4e85a962efab2f95db" className="betCard mt-1">
                                                <div className="d-flex">
                                                    <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                                                        PLAYING FOR<img className="mx-1" src={rup} width="21px" alt="" />{user.amount}</span>
                                                    {user.creator_id == userId && user.is_running == 'yes' ? (
                                                        <span>
                                                            <button className="m-1 mb-1 ml-auto btn-primary btn-sm">
                                                                <a style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => handleClick(user.id)}>View</a>
                                                            </button>
                                                        </span>
                                                    ) : null}

                                                    {user.joiner_id == userId && user.is_running == 'yes' ? (
                                                        <span>
                                                            <button className="m-1 mb-1 ml-auto btn-primary btn-sm" >
                                                                <a style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} onClick={() => handleClick(user.id)}>View</a>
                                                            </button>
                                                        </span>
                                                    ) : null}

                                                    <div className="betCard-title d-flex align-items-center text-uppercase"><span className="ml-auto mr-3">PRIZE<img className="mx-1" src={rup} width="21px" alt="" />{user.prize}</span></div>
                                                </div>
                                                <div className="py-1 row">
                                                    <div className="pr-3 text-center col-5">
                                                        <div className="pl-2"><img className="border-50" src={player} width="21px" height="21px" alt="" /></div>
                                                        <div style={{ lineHeight: 1 }}><span className="betCard-playerName">{user.label} </span></div>
                                                    </div>
                                                    <div className="pr-3 text-center col-2 cxy">
                                                        <div><img src={versus} width="21px" alt="" /></div>
                                                    </div>
                                                    <div className="text-center col-5">
                                                        <div className="pl-2"><img className="border-50" src={player} width="21px" height="21px" alt="" /></div>
                                                        <div style={{ lineHeight: 1 }}><span className="betCard-playerName">{user.battle_id}</span></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* </>
                                        ))} */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};
export default LudoPopular;