import React,{useState, useEffect} from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import * as IoIcons from "react-icons/io";
// import { IconName } from "react-icons/io";
import logo from '../images/kkhelchampian.png';
import wallet from '../images/sidebar-wallet.png';
import support from '../images/sidebar-support.png';
import referEarn from '../images/sidebar-referEarn.png';
import notifications from '../images/sidebar-notifications.png';
import gamepad from '../images/gamepad.png';
import frndsend from '../images/frndsend.png';
import gamesHistory from '../images/sidebar-gamesHistory.png';
import history from '../images/order-history.png';
import axios from "axios";

// const Notification = () => {

//   const [users, setUsers] = useState([]);


//   useEffect(
//     () => {
//       loaduser();
//       // alert('game')

//     }, []
//   );

//   const loaduser = async () => {
//     alert('pppp');
//     var data = '';
//     const token = localStorage.getItem("token_store_vplay");
//     var config = {
//       method: 'post',
//       url: 'https://api.khelchampion.com/api/users/notification-count',
//       headers: {
//         'x-access-token': token
//       },
//       data: data,
//       // setdata: () => {
//       //     sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjczNDM1OTMxLCJleHAiOjE3MDQ5NzE5MzF9.lGDH-fUMWZpQzsOE7fD-xNQmirQM-Yvrlo3bFekhVBs')
//       // }
//     };

//     axios(config)
//       .then(function (response) {
//         const res = JSON.stringify(response.data);
//         const objectRes = JSON.parse(res);
//         console.log(objectRes.data);
//         // alert(objectRes.data)
//         setUsers(objectRes.data);
//         // config.setdata();
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }

// }



export const SidebarData = [

  {
    title: "profile",
    path: "/profile",
    // icon: <IoIcons.IoIosPaper />,
    icon: <div className="sideNav-icon"><i class="fa fa-user-circle" aria-hidden="true"></i></div> ,
    cName: "nav-text menu_list",
  },
  {
    title: "Game",
    path: "/dashbord",
    // icon: <IoIcons.IoIosPaper />,
    icon: <div className="sideNav-icon"> <i class="fa fa-gavel" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  {
    title: "Add Money/Withdraw",
    path: "/wallet",
    icon: <div className="sideNav-icon"><i class="fa fa-rupee" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  // {
  //   title: "Send-Money To Friend",
  //   path: "/send-money",
  //   icon: <img src={frndsend} alt="" />,
  //   cName: "nav-text menu_list",
  // },
  {
    title: "Game-History",
    path: "/game-history",
    icon: <div className="sideNav-icon"> <i class="fa fa-trophy" aria-hidden="true"></i> </div>,
    cName: "nav-text menu_list",
  },
  {
    title: "Top-10-Players",
    path: "/top-10-players",
    icon: <div className="sideNav-icon"> <i class="fa fa-history" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  {
    title: "Transaction History",
    path: "/transaction-history",
    icon: <div className="sideNav-icon"><i class="fa fa-rupee" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  {
    title: "Refer-Earn",
    path: "/refer-earn",
    icon: <div className="sideNav-icon"> <i class="fa fa-user-plus" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  // {
  //   title: "Refer-History",
  //   path: "/refer-earn",
  //   icon: <div className="sideNav-icon"> <i class="fa fa-users" aria-hidden="true"></i></div>,
  //   cName: "nav-text menu_list",
  // },
  // {
  //   title: "Palenty-History",
  //   path: "/refer-earn",
  //   icon: <div className="sideNav-icon"> <i class="fa fa-minus-square" aria-hidden="true"></i></div>,
  //   cName: "nav-text menu_list",
  // },
  {
    
    title: "Notification",
    path: "/notification",
    icon: <div className="sideNav-icon"> <i class="fa fa-bell" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  {
    title: "Support",
    path: "/support",
    icon: <div className="sideNav-icon"> <i class="fa fa-question-circle" aria-hidden="true"></i></div>,
    cName: "nav-text menu_list",
  },
  

];
