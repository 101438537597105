

import React from "react";
import { Outlet, Link } from "react-router-dom";
const Responsiblegaming = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area"  >
          <div className="m-3">
             {/*   <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
             <li className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></li> 
                <li className="breadcrumb-item active" aria-current="page">Responsible Gaming</li>
              </ol>
            </nav>*/}
            <div className="row">
              <div className="col-md-12">
                <h3 className="heading_design text-start">Responsible Gaming</h3>
              </div>
              <div className="col-12">
                <div className="about_heading">

                  <h4>Our Mission Statement:</h4>
                </div>
                <ul className="dashboard_paragraph" >
                  <li>
                    Mess around 24x7 urges every one of its players to capably play. We are focused on
                    assisting players
                    who with wishing to quit playing or who wish to restrict the sum that they play.<br /><br /> </li>
                  <div className="about_heading">

                    <h4>Ensure Responsible Play</h4>
                  </div>
                  <ul className="dashboard_paragraph" >
                    <li>Players must be 18 years of age to play skill games for real money.</li>
                    <li>Players can request for their accounts to be temporarily blocked, if they want
                      to
                      self-exclude themselves for some time.</li>
                    <li>Players can follow our ‘Guide to Responsible Play’ in order to keep a check on
                      their play
                      behaviour.</li>
                  </ul>
                  <div className="about_heading">

                    <h4>Guide to Responsible Play </h4>
                  </div>
                  <li>At times, players might find it hard to perceive that their internet based play is
                    gaining out of
                    influence. A typical response is to limit, stow away or deny such issues and the damage
                    it very well
                    may cause. Certain individuals will deceive themselves about how much cash or time is
                    being spent on
                    internet playing. </li>
                  <h6>Following are some of the best practices to help you play responsibly:</h6>
                  <ul className="dashboard_paragraph" >
                    <li>Play with some restraint and just for diversion.</li>
                    <li> Try not to play to bring in cash or break issues.</li>
                    <li> Never pursue your misfortunes while playing.</li>
                    <li> Put away a diversion spending plan.</li>
                    <li> Monitor the time and screen how much cash you spend.</li>
                    <li> Utilize the Add Money limit choice to assist with controlling the sum you
                      spend.</li>
                    <li> Balance the time you enjoy on playing web based games with other relaxation
                      exercises.</li>
                  </ul>
                  <div className="about_heading">

                    <h4>Recognize whether you are not playing responsibly</h4>
                  </div>
                  <h6>Do you do any of the following:
                  </h6>
                  <ul className="dashboard_paragraph" >
                    <li>Do you invest more cash and energy than you plan to messing around?</li>
                    <li>Do you feel regretful or embarrassed while game playing propensities?</li>
                    <li>Do you attempt to win back your misfortunes?</li>
                    <li>Do you miss significant things in life, for example, family time, work,
                      recreation
                      exercises, arrangements to mess around?</li>
                    <li>Do you contemplate your interactivity the entire day?</li>
                    <li>Do you have contentions with companions or family about you playing
                      propensities?</li>
                    <li>Do you lie or take to get cash to mess around?</li>
                    <li>Do you have obligations or battled monetarily to mess around?</li>
                    <li>Has messing around adversely affected your expert life?</li>
                    <li>Do you have relationship issues like contentions, conflicts or loss of
                      Association with
                      loved ones?</li>
                    <li>Have you encountered an expansion in pressure, sorrow or fits of anxiety because
                      of messing
                      around?</li>
                  </ul>
                  <div className="about_heading">

                    <h4>Game Prudence</h4>
                  </div>
                  <li>  Game Judiciousness is an autonomous body which expects to help players, playing on
                    ability gaming sites, in integrating capable gaming propensities into their ways of
                    life. Game Reasonability is a non-judgemental stage furnishing you with private,
                    classified and liberated from cost mental directing, wherein you can recognize how solid
                    your gaming propensities are as well as get proficient direction from specialists. All
                    Game Reasonability specialists are guaranteed by iGaming Foundation.</li>   <a href>To Find
                      out More..</a>
                  <div className="about_heading">

                    <h4>Get Help from Game Prudence</h4>
                  </div>
                  <li>  Assuming that you suspect that you are confronting any of the issues referenced above
                    and accordingly have not been playing dependably, or on the other hand assuming you know
                    somebody who is confronting comparable issues, compassionately look for help right away.
                    You can find support by enrolling your solicitation at Game Reasonability.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Responsiblegaming;