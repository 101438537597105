import React from "react";
import { Link } from "react-router-dom";
const Privacypolicy = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area">
          <div className="m-3">
            {/*  <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
               <li className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></li> 
                <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
              </ol>
            </nav>*/}
            <div className="row">
            <div className="col-md-12">
                <h3 className="heading_design text-start" >Privacy Policy</h3>
              </div>
              <div className="col-12">
              
                <p className="dashboard_paragraph"> 
                  Introduction
                  This report makes sense of how One Entertainment Club gathers, cycles, stores or potentially shares any
                  private information as well as data from clients (mutually alluded to as "Data"). By
                  getting to or potentially utilizing the Administrations you assent the assortment, move,
                  control, capacity, divulgence and different purposes of your data as portrayed in this
                  Protection Strategy. In the event that you have any worries about giving information, or
                  having it utilized in any way allowed in this Security Strategy, you shouldn't utilize
                  the Administrations. As set out in the agreements relating the Administrations (the
                  "Terms"), you should be essentially at legitimate age (least age of 18 years) to get to
                  and additionally utilize the Administrations, or on the other hand if lawfully
                  conceivable, to access with your lawful watchman assent, due approval and concurrence
                  with these Protection Strategy.
                </p>
                <div className="about_heading">

                <h4>The Information collected:</h4>
                </div>
                <p className="dashboard_paragraph" >   
                One Entertainment Club or potentially outsiders, including yet not restricted to colleagues, promoting
                networks, examination or search data suppliers, may gather and handle the accompanying
                information about you: data that you give when you fill in structures while getting to and
                additionally utilizing the Administrations, or when you make a record inside the
                Administrations; subtleties of your utilization of the Administrations and the assets that
                you access; the correspondence among you and One Entertainment Club and any cooperations, or with different
                clients on the Help, the channels as well as any web-based entertainment related; from
                outsiders who hold information about you and who consent to impart this information to us;
                and data gathered by means of treats and other comparable advances and additionally
                different components, as made sense of further beneath.<br /><br />
                I. Treats: One Entertainment Club involves treats and other comparative advancements in the Administrations.
                These innovations work either by setting a little record which stores some data on your PC
                or cell phone; or potentially by getting to data on your gadget. One Entertainment Club utilizes treats and
                comparative innovations to remember you and your gadget, for instance by distinguishing your
                IP address; to permit the Administrations to connect with an outsider interpersonal
                organization or stage where you have decided to permit such collaboration; to permit
                installment processes when you submit installment guidelines; to empower One Entertainment Club and outsiders
                to furnish you with additional redid administrations; and to gather information, for
                example, your gadget's model, working framework and screen size, different applications
                introduced on your gadget, and data about how you utilize the Administrations. By getting to
                as well as utilizing the Administrations you assent the utilization of treats and
                comparative advances as per this Security Strategy. You can handicap treats through your web
                or cell phone program settings however a few elements of the Administrations may not work as
                expected. On the other hand, in the event that you don't wish such information assortment as
                portrayed in this segment, you ought to quit utilizing the Administrations..<br /><br />
                II. Promotions: Publicists on cell phones here and there use publicizing identifiers to
                empower and upgrade their publicizing, to convey tailor advertisements connected with your
                inclinations (Interest-Based Promoting or IBA). These identifiers are non-extremely durable,
                non-individual, gadget identifiers. One Entertainment Club or potentially outsiders might utilize your
                gadget's promoting identifier and other data related with it, to convey promotions that
                connect with your inclinations and to improve and quantify the viability of advertisement
                crusades.
                III. Area Data: You might decide to distribute your area in your One Entertainment Club profile. You may
                likewise advise your area when you empower your gadget to send such area data. One Entertainment Club or
                potentially outsiders might utilize and store data about your area to give unique
                highlights, to convey promotions that relate your inclinations and additionally to improve
                and modify the Administrations.<br /><br />
                IV. Joins: One Entertainment Club might monitor how you communicate with joins across the Administrations,
                including email notices, outsider administrations, and client applications, by diverting
                snaps or through different means.<br /><br />
                V. Log Information: Servers naturally record data made by your utilization of the
                Administrations. This information might incorporate data, for example, your IP address,
                program type, working framework, the alluding website page, pages visited, area, your
                portable transporter, gadget and application IDs, search terms, and treat data. Log
                information is gotten when you associate with the Administrations. This information is
                utilized to offer the Types of assistance and to quantify, alter, and further develop
                them.<br /><br />
                VI. Installment data: In the event that you make a buy in the Administrations, One Entertainment Club might
                gather the charging and monetary data important to deal with the charges; as well as do as
                such for the benefit of the pertinent installment specialist organizations. Acquisition of
                outsider administrations are dependent upon the strategies pertinent to such
                supplier.<br /><br />
                VII. Outsider administrations: One Entertainment Club utilizes an assortment of outsider administrations to
                help its arrangement of the Administrations, for example, facilitating and different
                administrations. These outsider specialist co-ops might gather data sent by your program as
                a feature of a website page demand, for example, treats or your IP address, area and
                gadgets' novel identifiers. Likewise, outsider promotion accomplices might share data to
                quantify promotion quality and designer advertisements, for instance to show advertisements
                about things you might have proactively shown interest in.<br /><br />
                VIII. Client care Correspondence: When you request help from One Entertainment Club client assistance, the
                contact data you give will be gathered, as well as data about your game play or movement on
                the Assistance, your client ID number, and the correspondence and any data held inside. In
                the event that accessible through the Administrations, you might furnish One Entertainment Club with profile
                data to unveil, for example, a short memoir, area, site, wireless, an image, data to modify
                your record, and so on. Such contact data might be utilized to send you data about the
                Administrations or related data. You might utilize your record settings to withdraw from
                warnings from One Entertainment Club and additionally from different clients and find clients you know. You
                may likewise withdraw by adhering to the directions held inside the notice or the guidelines
                on the Assistance. Likewise One Entertainment Club might utilize your contact data to help other people track
                down your record, including through outsider administrations and client applications. Giving
                any extra data depicted in this part is completely discretionary.<br /><br />
                <div className="about_heading">

                <h4>Use of Information</h4>
</div>
                At the point when you make or design a record in the Administrations, you give some private
                data, like your name, username, secret word, email address and some other data as expected
                chance to time. A portion of this data, for instance, your name or potentially username,
                might be recorded freely on the Administrations, remembering for your profile page and in
                query items. You concur that One Entertainment Club and additionally outsiders for its sake or potentially
                its accomplices might involve the Data for the reasons for reaching you as a component of
                client care; to send you updates or data about the Administrations; dealing with your record
                and relationship with the Help and further developing your experience when you use it,
                working on the Administrations, exploration, studying, and drawing in with you, for instance
                by sending you correspondences for these reasons; showcasing and advancement of the
                Administrations or items; to customize and enhance the Administrations, special substance as
                well as promoting; to make reports, examination or comparative administrations for the
                motivations behind examination or business knowledge.
                <div className="about_heading">
                <h4>Information sharing and disclosure</h4>
                </div>
                One Entertainment Club will just impart your information to outsiders as per the Security Strategy, as
                sensibly essential to offer the Types of assistance, for instance, by giving Data to
                providers that One Entertainment Club might use to satisfy the Administrations; where it is important to
                complete your guidelines, for instance, to handle an installment guidance your Data must be
                given to the installment processors; where your information is on an unknown and collected
                premise, meaning you were unable to be by and by recognized from it; for the conveyance of
                Interest-Based Promoting in the way set out on this Security Strategy; when you submit data
                as a feature of a rivalry or in any case communicate with any channel or virtual
                entertainment, the data you submitted might be distributed; as it's sensibly accepted is
                allowed by regulation or guideline; to consent to any legitimate commitment, or to implement
                or apply the Terms, this Security Strategy or potentially some other concurrence with you;
                or to safeguard the privileges as well as property of One Entertainment Club or outsider's freedoms and
                additionally property. One Entertainment Club might share or reveal your non-private data, for example,
                public client profile data, public messages, email, and so on, or share or unveil your data
                in an unknown or collected premise in a way that doesn't permit your own recognizable
                proof.<br /><br />
                Collaboration with informal communities as well as stages<br /><br />
                You might permit the Administrations to collaborate with any outsider informal community as
                well as stages, like Facebook, twitter, whatsApp and some other organizations/stages which
                will give information about you to One Entertainment Club. Since some other applications or sites unique in
                relation to the Administrations are claimed by an outsider, you should guarantee that you
                read their terms of administration and the pertinent security strategies. You comprehend
                that when you permit the Administrations to connect with any outsider informal community or
                potentially stage, One Entertainment Club might impart information about you to your contacts and different
                clients of the Administrations as well as the other way around. This Information might
                incorporate your name, profile picture, action status, and data connected with your
                utilization of the Administrations. You can change this by changing your settings with that
                outsider supplier.<br /><br />
                One Entertainment Club has specific connections inserted to outsider administrations including yet not
                restricted to YouTube. Your cooperation with such outsider stage/s are administered by their
                approaches, and we ask you to audit their arrangements before you continue with profiting
                such administrations by means of the contributions of One Entertainment Club. The YouTute expressions are
                accessible at https://www.youtube.com/t/terms; and the Google Protection Strategy is
                accessible at https://policies.google.com/privacy?hl=en-US. Additionally, to control your
                association with Google account or their administrations permits you to control your
                privileges and exercises, and might be gotten to at
                https://myaccount.google.com/permissions?pli=1.<br /><br />
                <div className="about_heading">
                <h4>Term</h4>
                </div>
                One Entertainment Club might hold the Data however long is important to satisfy the reasons for which it was
                gathered or on a case by case basis to offer the Types of assistance, even after you have
                stopped or erased any record, or after the finish of the arrangement of the Administrations,
                on the off chance that maintenance of such Data is sensibly important to consent to lawful
                commitments, meet administrative necessities, resolve questions between clients, forestall
                extortion, or some other use.
                <div className="about_heading">

                <h4>Protection of Information</h4>
                </div>
                One Entertainment Club keeps up with proper specialized and actual shields to safeguard Data against
                unintentional or unlawful obliteration or misfortune, change, unapproved divulgences or
                access, and some other unlawful types of handling of the information in its control. In any
                case, One Entertainment Club doesn't ensure that Data won't be gotten to, uncovered, changed or annihilated
                by break of any of the previously mentioned shields. Data might be moved to and additionally
                put away at overall objections. One Entertainment Club makes all strides sensibly important to guarantee that
                Data is dealt with safely and as per this Security Strategy. If One Entertainment Club is engaged with an
                insolvency, consolidation, securing, redesign or offer of resources, your data might be sold
                or moved as a feature of that exchange. The endeavors in this Protection Strategy will apply
                to the Data as moved to the new substance.<br /><br />
                In the Administrations you might track down connects to outsider sites. You comprehend that
                when you click on these connections any information which you give a short time later is
                dependent upon that outsider's security strategy and not to One Entertainment Club's. Thusly, One Entertainment Club gets a
                sense of ownership with the substance, wellbeing or security of any outsider site. The
                Administrations are not coordinated to people under legitimate age. One Entertainment Club intentionally
                gathers no Data from kids under lawful age. Assuming that you become mindful that a kid
                under lawful age has given individual data, steps will be taken to eliminate such data and
                end such record. Assuming you become mindful that any kid has given individual data without
                the fundamental legitimate gatekeeper assent, if it's not too much trouble, contact:
                oneentertainmentclub@outlook.com
. Regardless of which country you live in or supply data from, you approve
                One Entertainment Club to store as well as utilize the Data as per this Security Strategy in any nation where
                One Entertainment Club might work.<br /><br />
                Despite anything in actuality in this Strategy, One Entertainment Club might save or unveil your data to the
                degree sensibly important to follow a regulation, guideline or lawful solicitation; to
                safeguard the wellbeing of any individual; to address misrepresentation, security or
                specialized issues; or to safeguard One Entertainment Club's privileges or property. Notwithstanding, nothing
                in this Security Strategy is planned to restrict any legitimate safeguards or complaints
                that you might have to an outsider's, including an administration's solicitation to uncover
                your data. One Entertainment Club needs to ensure that your Data is exact and exceptional. You might request
                to change, right or eliminate data with the devices and record settings of the Help, or in
                any case by reaching One Entertainment Club at <a href="oneentertainmentclub@outlook.com
." /><br /><br />
                Assuming any court or other equipped power views any of this Security Strategy as invalid or
                unenforceable, different terms of this Protection Strategy won't be impacted. This Security
                Strategy is administered by and deciphered as per the laws of Nagaland State Government as
                well as Republic of India. Any question emerging regarding this Security Strategy will be
                dependent upon the selective ward of the courts situated in the city of Satna/Madhya Pradesh
                - India. You assent the purview and scene in such courts and forgo any complaint as to
                awkward gathering One Entertainment Club might change or correct this Protection Strategy every once in a
                while.<br /><br />
                </p>
                <p />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Privacypolicy;