import React, { useEffect, useState } from "react";
import axios from "axios";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "../App.css";
import { IconContext } from "react-icons";
import logo from '../images/logo.png';
import imagemoney from '../images/global-rupeeIcon.png';
import sidebaricon from '../images/header-hamburger copy.png';
import earnmoney from '../images/earn_icon.png';
import $ from 'jquery';
import { IoMdRefresh } from "react-icons/io";

// import Newdashbord  from "./routes/Newdashbord "

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  
  $('.nav-menu').on('click',function(){
    $('.nav-menu').removeClass('active');
  });
  const [users, setUsers] = useState([]);
  useEffect(
    () => {
      const token = localStorage.getItem("token_store_vplay");
      if(token == null){
        window.location.href = '/login';
      }
      loaduser();
      multiple_auth_chk();
    }, []
  );
    const [userprofile, setUserprofile] = useState(
    {
      username: ""
    }
  )
    const logout = () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/logout`,
      headers: {
        'x-access-token': token
      },
      data: userprofile
    };
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.log(objectRes.status);
        // loaduser();
        // setEditflag(false);
        if (objectRes.status == 1) {
          localStorage.clear();
          // navigate('/login')
          window.location.href = 'login';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // alert('aaa');
    // localStorage.clear();
  }
  const multiple_auth_chk = async () => {
    

    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    console.warn(ipaddress)
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/multiple-auth-chk`,
      headers: {
        'x-access-token': token
    },
    data: {ipaddress:ipaddress}
    };
  
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.warn(objectRes.data);
        var totlaipp = objectRes.data[0]['ip'];
        // alert(totlaipp);
        if(totlaipp != ipaddress){
          // localStorage.setItem('token_store_vplay');
          localStorage.clear();
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
      // if(!token){
      //   window.location.href = '/login';
      //   // exist();
      // }
  }
  const loaduser = async () => {


    var data = '';

    const token = localStorage.getItem("token_store_vplay");
    // var config = {
    //   method: 'post',
    //   url: 'https://api.khelchampion.com/api/users/header-amount',
    //   headers: {
    //     'x-access-token': token
    // },
    //   data: data,
    // };
     const userid = localStorage.getItem("userid");
    var config = {
      method: 'post',
      // url: 'https://api.kheldost.com/api/users/header-amount',
      // headers: {
      //   'x-access-token': token,
      // },
      url: `${process.env.REACT_APP_API_URL_ADMIN}/api/header.php?user_id=`+userid,
    //   body: {
    //     'user_id': userid,
    // },
      user_id: userid,
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes[0]);
        setUserprofile({ ...userprofile, "username": objectRes[0].username })
        if (objectRes[0].verify == 'inactive') {
          logout();
        }
         if (objectRes[0].banned == '1') {
          logout();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      if(!token){
        window.location.href = '/login';
        // exist();
      }
  }
  const sayHello = () => {
   window.location.reload();
  }
  
  return (
    <>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className="navbar fixed=top headerContainer">
          <Link to="#" className="menu-bars">
            {/* <FaIcons.FaBars onClick={showSidebar} /> */}
            <img src={sidebaricon} onClick={showSidebar} width="19px"/>
          </Link>
          <div className="logo1">
            <Link className="box" to="/dashbord">
              <picture className=" navLogo d-flex">
                <img src={logo} alt="" />
              </picture>
            </Link>
          </div>

          <div className="menu-items">
            {/* <div id="walletBalance">
					
					</div> */}
        
            {/* <button className="btn refresh" onClick={sayHello}>Refresh</button> */}
            <IoMdRefresh  className="text-dark mr-3 resfresh_btn_new"  onClick={sayHello}/>
      
            <Link className="box box_amount_add" to="/add-fund">
              <picture className="moneyIcon-container"><img src={imagemoney} alt="money image" /></picture>
              <div className="mt-1 ml-1">
                <div className="moneyBox-header">Cash</div>
                <div className="moneyBox-text">₹{users.wallet}</div>
              </div>
              <picture className="moneyBox-add money_add_remove"><i class="fa fa-plus" aria-hidden="true"></i>
                                           </picture>
            </Link>&nbsp;
            <Link className="box box_amount_add" to="/reffer-amount">
              <picture className="moneyIcon-container"><img src={earnmoney} alt="earn image" /></picture>
              <div className="mt-1 ml-1">
                <div className="moneyBox-header">EARNING</div>
                <div className="moneyBox-text">₹{users.redeem}</div>
              </div>
              <picture className="moneyBox-add money_add_remove"><i class="fa fa-plus" aria-hidden="true"></i></picture>
            </Link>
          </div>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    <div className="sidebar_data text-center">
                    {item.icon}
                    <span>{item.title}</span>
                    </div>
                    <i class="fa fa-angle-right"></i>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;