
import React from "react";
import { Outlet, Link } from "react-router-dom";

const Addmoney = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area"  >
          <div className="m-3">

            <h2 className="heading_design">What is Add Conditions ?</h2>
            {/* <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <h6 className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></h6>
                <h6 className="breadcrumb-item active" aria-current="page">What is Add Conditions ?</h6>
              </ol>
            </nav> */}
            <div className="row">
              <div className="col-12">
                <ol className="rules-list">
                  <li>Any condition you want you can add with your bet amount is showing to other player
                    so you find your perfect match player with same condition.</li>
                  <li>Add condition is like you want to play game with level of ludo is 10+ then your
                    condition is level 10+
                    And other is if you want to play with fresh id of your ludo.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Addmoney;