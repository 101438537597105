import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Addfund = () => {
  const [usserid, setUserId] = useState('');
  const [inputValuedeposit, setInputValuedeposit] = useState('');
  const [users, setUsers] = useState([]);
  const [paymentsetting, setUserspaymentsetting] = useState([]);
console.warn(paymentsetting)
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userid");
    setUserId(userId);
    loaduser()
  }, []);

  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-profile`,
      headers: {
        'x-access-token': token
      },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const res1 = JSON.stringify(response.data);
        // console.warn(res1)
        const objectRes = JSON.parse(res);
        const objectRess = JSON.parse(res1);
        setUsers(objectRes.data[0]);
        setUserspaymentsetting(objectRess.paymentsetting[0]);


      })
      .catch(function (error) {
        console.log(error);
      });

  }




  const handleDeposit = () => {
    const userId = localStorage.getItem("userid");
    const minDeposit = parseInt(paymentsetting.min_v);
    const maxDeposit = parseInt(paymentsetting.max_v);
    const depositAmount = parseInt(inputValuedeposit);
  
    if (isNaN(depositAmount)) {
      toast.error("Invalid deposit amount");
      return;
    }
  
    if (depositAmount < minDeposit) {
      toast.error(`Minimum Deposit Amount ${minDeposit}`);
      return;
    }
  
    if (depositAmount > maxDeposit) {
      toast.error(`Maximum Deposit Amount ${maxDeposit}`);
      return;
    }
  
    const apiUrl = `https://badshahludo.com/Admin/public/manual-deposit?name=${users.vplay_id}&userid=${userId}&amount=${depositAmount}&contact=${users.mobile}`;
    window.location.href = apiUrl;
  };
  


  const handleClick = () => {
    const amount = parseFloat(inputValuedeposit);
    if (amount >= 10 && amount <= 20000) {
      navigate('/Deposit-Amount');
      localStorage.setItem('deposit_amount', inputValuedeposit);
    } else {
      alert('Please enter an amount between ₹10 and ₹20,000.');
    }
  };

  return (
    <>
      <div className="leftContainer">
        <div className="px-2 py-2">
          {[

            'danger',

          ].map((variant) => (
            <Alert key={variant} variant={variant} className="p-2 alert_message mb-0" dismissible>
              <p className="mb-0">ध्यान दें पैसा जोड़ते समय ओहके का इंतजार करे ! धन्यवाद</p>
              <p className="mb-0">Payment Add Karte Time Ok Hone Tak Ka Wait Karen ! Thanks</p>
            </Alert>
          ))}

          <div className="games-section">

            <div className="d-flex position-relative align-items-center">
              <div className="games-section-title">Choose amount to add</div>
            </div>
          </div>
          <form>
            <div className="pb-3">
              <div className="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
                <div className="MuiFormControl-root MuiTextField-root">
                  <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true">Enter Amount</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                    <input
                      type="number"
                      onChange={(e) => setInputValuedeposit(e.target.value)}
                      required
                      name="amount"
                      className="form-control border-bottom"
                      placeholder="₹"
                      style={{ border: 'none' }}
                      value={inputValuedeposit}
                    />
                    <input
                      type="hidden"
                      value={usserid}
                      name="userid"
                      className="form-control border-bottom"
                    />
                  </div>
                  <p className="MuiFormHelperText-root">Min: 10, Max: 20000</p>
                </div>
              </div>

              <div className="games-window">
                <div className="gameCard-container" id="amount1">
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("100")}>

                    <span style={{ fontSize: '0.9em' }}>₹</span>100
                  </button>
                </div>
                <div className="gameCard-container" id="amount2">

                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("250")}>

                    <span style={{ fontSize: '0.9em' }}>₹</span>250
                  </button>
                </div>
                <div className="gameCard-container" id="amount3">

                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("500")}>

                    <span style={{ fontSize: '0.9em' }}>₹</span>500
                  </button>
                </div>
                <div className="gameCard-container" id="amount4">

                  <button type="button" className="add-fund-box font_rupee" onClick={() => setInputValuedeposit("1000")}>

                    <span style={{ fontSize: '0.9em' }}>₹</span>1000
                  </button>
                </div>
              </div>
            </div>
            <div className="refer-footer add_fund_btn">
              <button
                disabled={!inputValuedeposit}
                type="button"
                className="disabledButton btn btn-primary btn-lg"
                style={{ width: '100%' }}
                // onClick={handleClick}
                onClick={handleDeposit}
              >
                NEXT
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Addfund;
