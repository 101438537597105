import React, { useEffect, useState, useRef } from "react";
import rup from "../images/coin.png";
import global from "../images/global-grey-iButton.png";
import player from "../images/Avatar2.png";
import versus from "../images/versus.png";
import battleIcon from "../images/global-purple-battleIcon.png";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import $ from "jquery";
import audio from "../images/battle_accepted.mp3";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
// import { useNavigate } from "react-router-dom";
import useSocket from './UseSocket';
import axios from "axios";
import io from "socket.io-client";
import useSound from "use-sound";
const token = localStorage.getItem("token_store_vplay");
// import socketIOClient from 'socket.io-client';
const LudoClassic = () => {
  const audioRef = useRef(null);
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [loadingbuttondelete, setLoadingbuttondelete] = useState(false);
  const [loadingbuttonstart, setLoadingbuttonstart] = useState(false);
  const [loadingbuttonstartuser, setLoadingbuttonstartuser] = useState(false);
  const [loadingbuttonstartrequest, setLoadingbuttonrequest] = useState(false);
  const [loadingbuttonset, setLoadingbuttonset] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const playAudio = () => {
    audioRef.current.play();
  };

  useEffect(() => {
    genralsetting();
  }, [true]);
  const genralsetting = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: "post",

      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-genral-setting`,

      headers: {
        "x-access-token": token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        if (objectRes.game1 == 0) {
          window.location.href = "/";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // =====Bat Create Api=====
  const [validationErrors, setValidationErrors] = useState({});
  const userId = localStorage.getItem("userid");
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  // const [socket, setSocket] = useState(null);
  const [receivedGamebat, setReceivedGamebat] = useState();
  const [receivedGamebatStotre, setReceivedGamebatStotre] = useState();
  const [receivedGame, setReceivedGame] = useState([]);
  const [isLoading, setLoad] = useState(true);
  let battles = [];
  const [gameself, setGameSelf] = useState([]);
  // const onDataReceived = (data) => {
  //   console.warn("hfhgfd",data)
  //   setReceivedGamebat(data);
  //   setReceivedGamebatStotre(data);
  // };

  // const socket = useSocket({ onDataReceived });
  useEffect(() => {
    const socket = io.connect(`${process.env.REACT_APP_API_URL_NODE}`);
    socket.on("rows", function (data) {

      const filteredData = data.filter(item => item.game_id === "1");
      // if (filteredData.length > 0) {
        console.warn("item.game_id === 1",filteredData)
        setReceivedGamebat(filteredData);
        setReceivedGamebatStotre(filteredData);
      // }else{
      //   setReceivedGamebat([]);
      //   setReceivedGamebatStotre([]);
      //   const filteredData = data.filter(item => item.game_id === "1");
      // }
    });

  }, []);

  const [gamebat, setGamebat] = useState({
    game_url: "Ludo-Classic",
    amount: "",
    label: "",
    game_id: "1",
  });
  const [gameid, setgameid] = useState({
    game_id: "1",
  });

  const { amount, label } = gamebat;
  const oninputchange = (e) => {
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
  };



  const onSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_store_vplay");
    if (formSubmitting) return;

    // Set form submitting state to true
    // setFormSubmitting(true);

    setLoadingbuttonset(true);
    setFormSubmitting(true);
    // setTimeout(() => {
    // }, 3000);
    try {
      const errors = {};

      if (!amount) {
        errors.amount = "Amount is required";
      } else if (amount < 50) {
        errors.amount = "Minimum bet amount is 50";
      } else if (amount > 500) {
        errors.amount = "Maximum bet amount is 500";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      if (amount === null) {
        return;
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL_NODE}/game-bat-store`,
        headers: {
          "x-access-token": token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        data: gamebat,
      };

      // Disable button
      // $("#set_submit").prop("disabled", true);

      const response = await axios(config);

      // if (response.data.message === "Successfully") {
      //   toast.success(`Game-Bat-Store Successful!`);


      // } else {
      //   toast.error(response.data.message);
      // }
      if (response.data.status == 1) {
        loaduser();
        toast.success(`Game-Bat-Store Successfully!`);

      } else {
        toast.error(response.data.message);

      }
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setTimeout(() => {
        setLoadingbuttonset(false);
        setFormSubmitting(false);
      }, 1000);
    }
  };



  const onInputChange = (e) => {
    var ledata = e.target.value;
    var dd = ledata.length;
    if (dd > 20) {
      $(".clrcon").val("");
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: <strong>Please Enter last 20 words</strong>,
        icon: "error",
        timer: 1500,
      });
    }
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
  };

  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState([]);

  useEffect(() => {
    loaduser();
  }, []);
  useEffect(() => {
    multiple_auth_chk();
  }, []);

  const multiple_auth_chk = async () => {
    var data = "";
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: "post",
      // url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/multiple-auth-chk`,
      headers: {
        "x-access-token": token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: { ipaddress: ipaddress },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]["ip"];
        if(totlaipp != ipaddress){
          // localStorage.setItem('token_store_vplay');
          localStorage.clear();
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
    // if (!token) {
    //   // window.location.href = "/login";
    //   // exist();
    // }
  };
  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    const userid = localStorage.getItem("userid");

    var data = "";
    var config = {
      method: "post",
      // https://api.vplay.bet/api/users/gameopen
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/gameopen`,
      gameid,
      // url: `${process.env.REACT_APP_API_URL}/gameopen/${gameid.game_id}/${userid}`,
      headers: {
        "x-access-token": token,
      },
      data: gameid,
    };
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);

        setUsers(objectRes);
        battles = objectRes.map(battle => ({ ...battle, createdAt: new Date() }));
        // console.warn("battles",battles)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RingUpdateupdate = async (e) => {
    const apiUrl = `${process.env.REACT_APP_API_URL_NODE}/RingUpdateupdate-react`;

    const formData = new FormData();
    formData.append("userid", e);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }).then((response) => { });
    } catch (error) {
      console.error("An error occurred while making the API request:", error);
    }
  };

  // ======gameRunningApi======

  const [game, setGame] = useState([]);
  // const [gameself, setGameSelf] = useState([]);
  useEffect(() => {
    GameRunning();

    //   console.warn(game)
  }, []);

  const GameRunning = async () => {
    const token = localStorage.getItem("token_store_vplay");

    var data = "";
    // console.log(data)
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/gameRunning`,
      headers: {
        "x-access-token": token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: gameid,
    };

    axios(config)
      // console.log(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data);
        setGame(objectRes.data);
        // console.warn("data",objectRes.data)
        setGameSelf(objectRes.data1);
        // console.warn("data1",objectRes.data1)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Api lobby-delete----------
  function Delete(id) {
    const token = localStorage.getItem("token_store_vplay");
    var data = {
      id: id,
      game_id: "1",
    };
    battles = battles.filter(battle => battle.battleprimaryid !== id);
    console.warn("battles",battles)
    console.warn("id",id)
    setLoadingbuttondelete(true); 

    axios.post(
      `${process.env.REACT_APP_API_URL_NODE}/lobby-delete`,
      data,
      {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      }
    )
      .then(function (response) {
        GameRunning(); // Assuming GameRunning is a function you've defined elsewhere
        loaduser();
        // alert('Resource deleted successfully');
        // console.warn(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoadingbuttondelete(false); // Set loading back to false regardless of success or failure
      });
  }
  function checkBattlesTime() {
    const currentTime = new Date();
    battles.forEach(battle => {
        const elapsedTime = currentTime - new Date(battle.createdAt); 
      // console.warn("currentTime",currentTime,battle.createdAt)
        if (elapsedTime > 5 * 60 * 1000) {
          Delete(battle.battleprimaryid);
            // console.warn("battle.id",battle.battleprimaryid)
        }
    });
}
setInterval(checkBattlesTime, 1000);

  const Playuser = async (id) => {
    const token = localStorage.getItem("token_store_vplay");
    var data = {
      id: id,
      game_id: "1",
    };
    // try {
    setLoadingbutton(true);
    const config = {
      method: "post",
      // url: `http://localhost:4001/lobby-send-request`,
      url: `${process.env.REACT_APP_API_URL_NODE}/lobby-send-request`,
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: JSON.stringify(data),
    };
    try {
      const response = await axios(config);
      GameRunning();
      // loaduser();

      if (response.data.status == 0) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: "error",
        });
      }
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }

    finally {
      setLoadingbutton(false);
    }
  };

  const Startuser = async (id) => {
    // alert(id);
    const token = localStorage.getItem("token_store_vplay");
    var data = {
      id: id,
      game_id: "1",
    };
    try {
      setLoadingbuttonstartuser(true)
      const config = {
        method: "post",
        // url: 'https://api.vplay.bet/api/users/lobby-start',
        url: `${process.env.REACT_APP_API_URL_NODE}/lobby-start`,

        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      try {
        const response = await axios(config);
        GameRunning();
        loaduser();
        // console.warn(response.data);
        if (response.data.status == "1") {
          StartuserChkOpsite(id);
          // window.location.href = `/Battlechat?id=${id}`;
          // StartuserChkOpsite()
          // GameRunning();
        } else {
          const MySwal = withReactContent(Swal);
          MySwal.fire({
            title: response.data.message,
            icon: "error",
          });
        }
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
    finally {
      setLoadingbuttonstartuser(false)
    }
  };
  const StartuserChkOpsite = async (id) => {
    // alert(id);
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL_ADMIN}/chk-aponanat-player/${id}`,
    };
    try {
      const response = await axios(config);
      console.log(response.data.msg);
      if (response.data.msg == 1) {
        window.location.reload();
      } else {
        window.location.href = `/Battlechat?id=${id}`;
      }
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const Rejecttuser = async (id) => {
    const token = localStorage.getItem("token_store_vplay");
    var data = {
      id: id,
      game_id: "1",
    };
    try {
      setLoadingbuttonrequest(true)
      const config = {
        method: "post",
        // url: 'https://api.vplay.bet/api/users/lobby-reject-request',
        url: `${process.env.REACT_APP_API_URL_NODE}/lobby-reject-request`,

        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        data: JSON.stringify(data),
      };

      try {
        const response = await axios(config);
        // console.warn(response.data);
        loaduser();

        // alert("hhjhj")
        // const socket = io('http://localhost:4001');
        //         setSocket(socket);
        //         socket.on('rows', (rows) => {
        //             console.warn('Received send by request api from server:', rows);
        //             setReceivedGamebat(rows);

        //         });
        if (response.data.message == "success") {
          setShowDeleteButton(true);
        } else {
          setShowDeleteButton(false);
        }
      } catch (error) {
        console.log(error);
        setShowDeleteButton(false);
      }
    } catch (error) {
      console.log(error);

    }
    finally {
      setLoadingbuttonrequest(false)
    }
  };

  const Startuserjoiner = async (id) => {
    const token = localStorage.getItem("token_store_vplay");
    var data = {
      id: id,
      game_id: "1",
    };
    try {
      setLoadingbuttonstart(true)
      const config = {
        method: "post",
        // url: 'https://api.vplay.bet/api/users/lobby-start-joiner',
        url: `${process.env.REACT_APP_API_URL_NODE}/lobby-start-joiner`,

        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        data: JSON.stringify(data),
      };

      try {
        const response = await axios(config);
        //  window.location.href = `/Ludo-Classic/Roomcode?id=${id}`;
        GameRunning();
        loaduser();

        window.location.href = `/Battlechat?id=${id}`;
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
    finally {
      setLoadingbuttonstart(false);
    }
  };

  const handleClick = (id) => {
    // alert(id);
    // window.location.href = `/Ludo-Classic/Roomcode?id=${id}`;
    window.location.href = `/Battlechat?id=${id}`;
  };

  return (
    <>
      <div className="leftContainer">
        <div className="container">
          <div>
            {/* <div className="main-area" style={{ paddingTop: "60px" }} /> */}
            <span className="cxy battle-input-header">Create a Battle!</span>
            <div className=" d-flex justify-content-center my-2">
              <form onSubmit={onSubmit}>
                <div className="d-flex">
                  <div>
                    <div
                      className=" set_amount"
                      style={{ paddingRight: 0, marginBottom: "15px" }}
                    >
                      <input
                        type="hidden"
                        name="game_url"
                        value={"Ludo Ulta"}
                      />
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="Gd9E2uC5KGeNReVLl0CZdcU1i7n4T8wTX6JsQ84R"
                      />
                      <div>
                        <input
                          className="form-control clrcon"
                          type="tel"
                          name="amount"
                          value={gamebat.amount}
                          onChange={onInputChange}
                          placeholder="Amount"
                        />
                        {validationErrors.amount && (
                          <p>{validationErrors.amount}</p>
                        )}
                        <div className="error" />
                      </div>
                    </div>
                    {/* <div className=" set_amount" style={{ paddingRight: "0px" }}>
                                            <input
                                                className="form-control clrcon"
                                                type="text"
                                                name="label"
                                                value={gamebat.label}
                                                onChange={onInputChange}
                                                placeholder="Ex.Level 10+"
                                                min={5}
                                                max={11}
                                                style={{ float: "left" }}
                                            />
                                        </div> */}
                  </div>
                  <div className="d-flex"> 
                    <div
                      className=""
                      style={{ marginBottom: "15px", marginLeft: "10px" }}
                    >
                      <button
                        disabled={loadingbuttonset || formSubmitting}
                        className="bg-success  set_btn btn text-white cxy position-static"
                        type="button"
                        onClick={onSubmit}
                      >
                        Set
                        {loadingbuttonset && (
                          <Spinner
                            animation="border"
                            style={{
                              marginLeft: '5px',
                              width: '15px',
                              height: '15px',
                              marginTop: "5px"
                            }}
                          />
                        )}
                      </button>
                    </div>
                    <div className="d-flex">
                      <Link to="/info-conditions">
                        <img
                          className="ml-2"
                          src={global}
                          alt=""
                          style={{ paddingLeft: "21px",paddingTop:
                        "8px" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <button class="bg-green playButton cxy position-static" type="submit" style="margin: 3px; float:right">Set</button> */}
              </form>
            </div>
          </div>
        </div>

        <div className="divider-x" />
        <div className="px-4 py-3">
          <div className="mb-2 position-relative d-flex justify-content-between">
           <div>
           <img src={battleIcon} width="20px" alt="" />
            <span className="ml-2 games-section-title">Open Battles</span>
           </div>
            <Link to="/rules">
              <span
                className="games-section-headline text-uppercase font-weight-bold">
                Rules
                <img className="ml-2" src={global} alt="" />
              </span>
            </Link>
          </div>
          {receivedGamebat &&
            receivedGamebat.map((user) => (
              <>
                {user.is_running == "no" && user.game_status != "3" && (
                  <div class="wrappers">
                    <div id="slides">
                      <>
                        <div id="openBattle">
                          <div
                            id="6345971c71f80dea71bf71cf"
                            className="betCard mt-1"
                          >
                            <span className="betCard-title pl-3 d-flex align-items-center text-uppercase justify-content-between">
                              CHALLENGE FROM{" "}
                              {user && user.vplay_id}
                              {/* <span className="ml-1" style={{ color: 'brown' }}></span> */}
                              {user.creator_id == userId &&
                                user.request_status == 0 ? (
                                <button
                                  disabled={loadingbuttondelete}
                                  className="m-1 mb-1 ml-auto btn-danger btn-sm"
                                  style={{ padding: "2px 2px 2px 2px" }}
                                  onClick={() => Delete(user.id)}
                                >
                                  <span
                                    style={{
                                      fontSize: "9px",
                                      color: "white",
                                      fontWeight: 800,
                                    }}
                                  >
                                    {" "}
                                    DELETE
                                  </span>
                                </button>
                              ) : null}
                              {user.creator_id == userId &&
                                user.request_status == 2 ? (
                                <div className="req_cancle122">
                                  <button
                                    disabled={loadingbuttonstartuser}
                                    className="mb-1  btn-success btn-sm"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: 800,
                                      padding: "6px 16px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => Startuser(user.id)}
                                  >
                                    Start
                                  </button>

                                  <button
                                    disabled={loadingbuttonstartrequest}
                                    className="m-1 mb-1 ml-auto btn-danger btn-sm"
                                    style={{}}
                                    onClick={() => Rejecttuser(user.id)}
                                  >
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        fontWeight: 800,
                                        padding: "6px 2px",
                                      }}
                                    >
                                      {" "}
                                      Reject
                                    </span>
                                  </button>
                                </div>
                              ) : null}
                              {/* {showDeleteButton && user.creator_id == userId && user.request_status == 0 ? (
                                                <button
                                                className="m-1 mb-1 ml-auto btn-danger btn-sm"
                                                style={{ padding: '2px 2px 2px 2px' }}
                                                >
                                                <a
                                                    href="#"
                                                    style={{ fontSize: '9px', color: 'white', fontWeight: 800 }}
                                                    onClick={() => Delete(user.id)}
                                                >
                                                    DELETE
                                                </a>
                                                </button>
                                            ) : null} */}
                              {user.creator_id == userId &&
                                user.request_status == 1 ? (
                                <div className="button_view">
                                  <button
                                    className="m-1 mb-1 ml-auto btn-primary btn-sm"
                                    onClick={() => handleClick(user.id)}
                                  >
                                    {/* <a href="/Ludo Ulta/Roomcode" style={{ fontSize: '12px', color: 'white', fontWeight: 800 }} >Viewssss</a> */}
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        fontWeight: 800,
                                      }}
                                    >
                                      View
                                    </span>
                                  </button>
                                </div>
                              ) : null}
                            </span>

                            <div className="d-flex justify-content-between pl-3">
                              <div className="d-flex">
                                <div className="pr-3 pb-1">
                                  <span className="betCard-subTitle">
                                    Entry
                                  </span>
                                  <div>
                                    <img src={rup} width="21px" alt="" />
                                    <span className="betCard-amount">
                                      {user.amount}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span className="betCard-subTitle">
                                    Prize
                                  </span>
                                  <div>
                                    <img src={rup} width="21px" alt="" />
                                    <span className="betCard-amount">
                                      {user.prize}
                                    </span>
                                  </div>
                                </div>
                                {/* <div style={{ marginTop: '4px', marginLeft: '15px', fontSize: '13px' }} align="center">
                                                <span className="betCard-subTitle" style={{ fontSize: '10px' }}>Condition</span>
                                                <div>{user.label}</div>
                                            </div> */}
                              </div>
                              {user.creator_id == userId &&
                                user.request_status == 0 ? (
                                <div className="text-center col-5 ml-auto mt-auto mb-auto">
                                  <div className="pl-2 text-center">
                                    <Spinner animation="grow" size="sm" />
                                  </div>
                                  <div style={{ lineHeight: 1 }}>
                                    <span
                                      className="Home_betCard_playerName__57U-C"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Finding Player!
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              {userId != user.creator_id &&
                                user.request_status == 0 ? (
                                <>
                                  <button
                                    disabled={loadingbutton}
                                    className="m-1 mb-1 play ml-auto btn btn-secondary btn-sm"
                                    style={{
                                      fontSize: "12px",
                                      color: "white",
                                      fontWeight: 800,
                                    }}
                                    onClick={() => {
                                      Playuser(user.id);
                                      playAudio();
                                    }}
                                  >
                                    Play
                                  </button>
                                  <audio ref={audioRef}>
                                    <source src={audio} type="audio/mpeg" />
                                  </audio>
                                </>
                              ) : null}

                              {userId != user.creator_id &&
                                user.request_status == 1 &&
                                user.joiner_id == userId ? (
                                <button
                                  disabled={loadingbuttonstart}
                                  className="start"
                                  style={{
                                    fontSize: "12px",
                                    color: "white",
                                    fontWeight: 800,
                                    width: "90px",
                                  }}
                                  onClick={() => Startuserjoiner(user.id)}
                                >
                                  Start
                                </button>
                              ) : null}

                              {userId != user.creator_id &&
                                user.request_status == 2 &&
                                user.joiner_id == userId ? (
                                <div className="req_cancle">
                                  <button className="m-1 mb-1 ml-auto btn btn-secondary btn-sm request_cancel">
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        fontWeight: 800,
                                      }}
                                    >
                                      request
                                    </span>
                                  </button>

                                  <button
                                    disabled={loadingbuttonstartrequest}
                                    className="m-1 mb-1 ml-auto btn-danger btn-sm request_cancel"
                                    onClick={() => Rejecttuser(user.id)}
                                  >
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "white",
                                        fontWeight: 800,
                                      }}
                                    >
                                      {" "}
                                      Cancel
                                    </span>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div id="myOpenBattles" />

                        <div
                          className="timebeforedata"
                          style={{ display: "none" }}
                        ></div>
                      </>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>

        <div className="px-4 py-3  position-relative">
          <div className="mb-2">
            <img src={battleIcon} width="20px" alt="" />
            <span className="ml-2 games-section-title">Running Battles</span>
          </div>
          {gameself &&
            gameself.map((user) => (
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed position-absolute"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                      style={{ width: "10%", top: 0, right: "30px" }}
                    ></button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    {/* {users && users.map((user) => (
                                            <> */}
                    <div className="accordion-body">
                      <div id="myRunningBattles" />
                      <div id="runningBattle">
                        {/* battle running*/}
                        <div
                          id="633c3b4e85a962efab2f95db"
                          className="betCard mt-1"
                        >
                          <div className="d-flex">
                            <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                              PLAYING FOR
                              <img
                                className="mx-1"
                                src={rup}
                                width="21px"
                                alt=""
                              />
                              {user.amount}
                            </span>
                            {user.creator_id == userId &&
                              user.is_running == "yes" ? (
                              <span>
                                <button
                                  className="m-1 mb-1 ml-auto btn-primary btn-sm"
                                  onClick={() => handleClick(user.id)}
                                >
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "white",
                                      fontWeight: 800,
                                    }}
                                  >
                                    View
                                  </span>
                                </button>
                              </span>
                            ) : null}

                            {/* {user.joiner_id == userId && user.is_running == 'yes' ? ( */}
                            <span>
                              <button
                                className="m-1 mb-1 ml-auto btn-primary btn-sm"
                                onClick={() => handleClick(user.id)}
                              >
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "white",
                                    fontWeight: 800,
                                  }}
                                >
                                  View
                                </span>
                              </button>
                            </span>
                            {/* ) : null} */}

                            <div className="betCard-title d-flex align-items-center text-uppercase">
                              <span className="ml-auto mr-3">
                                PRIZE
                                <img
                                  className="mx-1"
                                  src={rup}
                                  width="21px"
                                  alt=""
                                />
                                {user.prize}
                              </span>
                            </div>
                          </div>
                          <div className="py-1 row">
                            <div className="pr-3 text-center col-5">
                              <div className="pl-2">
                                <img
                                  className="border-50"
                                  src={player}
                                  width="21px"
                                  height="21px"
                                  alt=""
                                />
                              </div>
                              <div style={{ lineHeight: 1 }}>
                                <span className="betCard-playerName">
                                  {user.label}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="pr-3 text-center col-2 cxy">
                              <div>
                                <img src={versus} width="21px" alt="" />
                              </div>
                            </div>
                            <div className="text-center col-5">
                              <div className="pl-2">
                                <img
                                  className="border-50"
                                  src={player}
                                  width="21px"
                                  height="21px"
                                  alt=""
                                />
                              </div>
                              <div style={{ lineHeight: 1 }}>
                                <span className="betCard-playerName">
                                  {user.battle_id}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </>
                                        ))} */}
                  </div>
                </div>
              </div>
            ))}
          {/* {game && game.map((user) => (
                        <> */}
          {receivedGamebatStotre &&
            receivedGamebatStotre.map((user) => (
              <>
                {user.is_running == "yes" && user.game_status != "3" && (
                  <div class="wrapper">
                    <div id="slide">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingOne"
                          >
                            <button
                              className="accordion-button collapsed position-absolute"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                              style={{ width: "10%", top: 0, right: "30px" }}
                            ></button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                          >
                            {/* {users && users.map((user) => (
                                            <> */}
                            <div className="accordion-body">
                              <div id="myRunningBattles" />
                              <div id="runningBattle">
                                {/* battle running*/}
                                <div
                                  id="633c3b4e85a962efab2f95db"
                                  className="betCard mt-1"
                                >
                                  <div className="d-flex">
                                    <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                                      PLAYING FOR
                                      <img
                                        className="mx-1"
                                        src={rup}
                                        width="21px"
                                        alt=""
                                      />
                                      {user.amount}
                                    </span>
                                    {user.creator_id == userId &&
                                      user.is_running == "yes" ? (
                                      <span>
                                        <button
                                          className="m-1 mb-1 ml-auto btn-primary btn-sm"
                                          onClick={() => handleClick(user.id)}
                                        >
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "white",
                                              fontWeight: 800,
                                            }}
                                          >
                                            View
                                          </span>
                                        </button>
                                      </span>
                                    ) : null}

                                    {user.joiner_id == userId &&
                                      user.is_running == "yes" ? (
                                      <span>
                                        <button
                                          className="m-1 mb-1 ml-auto btn-primary btn-sm"
                                          onClick={() => handleClick(user.id)}
                                        >
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "white",
                                              fontWeight: 800,
                                            }}
                                          >
                                            View
                                          </span>
                                        </button>
                                      </span>
                                    ) : null}

                                    <div className="betCard-title d-flex align-items-center text-uppercase">
                                      <span className="ml-auto mr-3">
                                        PRIZE
                                        <img
                                          className="mx-1"
                                          src={rup}
                                          width="21px"
                                          alt=""
                                        />
                                        {user.prize}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="py-1 row">
                                    <div className="pr-3 text-center col-5">
                                      <div className="pl-2">
                                        <img
                                          className="border-50"
                                          src={player}
                                          width="21px"
                                          height="21px"
                                          alt=""
                                        />
                                      </div>
                                      <div style={{ lineHeight: 1 }}>
                                        <span className="betCard-playerName">
                                          {user.label}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="pr-3 text-center col-2 cxy">
                                      <div>
                                        <img src={versus} width="21px" alt="" />
                                      </div>
                                    </div>
                                    <div className="text-center col-5">
                                      <div className="pl-2">
                                        <img
                                          className="border-50"
                                          src={player}
                                          width="21px"
                                          height="21px"
                                          alt=""
                                        />
                                      </div>
                                      <div style={{ lineHeight: 1 }}>
                                        <span className="betCard-playerName">
                                          {user.battle_id}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </>
                                        ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default LudoClassic;
