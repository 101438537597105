
import React, { useEffect, useState } from "react";
import rup from '../images/global-rupeeIcon.png'
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import nogame from "../images/no-transaction.webp";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Offcanvas from 'react-bootstrap/Offcanvas';
import $ from 'jquery';
const Transactionhistory = () => {
    const [users, setUsers] = useState([]);
    const [SingleTransaction, setSingleTransaction] = useState([]);
    const [showModal, setShowModal] = useState(false);

    function singletran(name) {
        const token = localStorage.getItem("token_store_vplay");
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-transaction-history-single`,
            headers: {
                'x-access-token': token
            },
            data: { id: name },
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data.data);
                const objectRes = JSON.parse(res);
                var orderidshow = objectRes[0].order_id;
                var amount = objectRes[0].amount;
                var type = objectRes[0].type;
                var withdraw_status = objectRes[0].withdraw_status;
                if (withdraw_status == 'success') {
                    var successtransac = '<span class="transactionDetailssuccess">' + withdraw_status + '</span>';
                } else {
                    var successtransac = '<span class="transactionDetailspending">' + withdraw_status + '</span>';
                }
                var withdawtype = objectRes[0].withdawtype;
                var withdaw_detail = objectRes[0].withdaw_detail;
                if (type == 'web') {
                    var typetranc = 'User';

                    var paymentDetatils = '<hr><h5><strong>Payment Details</strong></h5><table className="table"><tbody><tr><td style="text-align:center;">' + withdawtype.toUpperCase() + '</td></tr><tr><td>' + withdaw_detail + '</td></tr></tbody></table>';

                } else {
                    var typetranc = 'Admin';
                    var paymentDetatils = '';
                }
                $('.singletableshow').html('<table className="table"><tbody><tr><td><strong>Order ID</strong></td><td style="margin-left:10px!important;">' + orderidshow + '</td></tr><tr><td><strong>Amount</strong></td><td>' + amount + '</td></tr><tr><td><strong>Withdraw By</strong></td><td>' + typetranc + '</td></tr><tr><td><strong>Status</strong></td><td><strong>' + successtransac + '</strong></td></tr></tbody ></table > ' + paymentDetatils)               // console.log(user);
                // config.setdata();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function OffCanvasExample({ name, ...props }) {
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);



        var data = name;
        if (show == true) {
            singletran(name);
        }

        return (
            <div>
                <span onClick={handleShow} className="me-2" style={{ position: "absolute", left: "74px" }}>
                    <i class="fa fa-eye"></i>
                </span>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Withdrawal</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="singletableshow"></div>



                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        );
    }

    useEffect(
        () => {
            loaduser();
            // alert('game')

        }, []
    );
    useEffect(
        () => {
            multiple_auth_chk();
        }, []
    );
    const multiple_auth_chk = async () => {


        var data = '';
        const ipaddress = localStorage.getItem("ipaddress");
        const token = localStorage.getItem("token_store_vplay");
        var config = {
            method: 'post',
            url: 'https://api.oneentertainmentclub.com/api/users/multiple-auth-chk',
            headers: {
                'x-access-token': token
            },
            data: { ipaddress: ipaddress }
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                // console.warn(objectRes.data[0]['total']);
                // var totlaipp = objectRes.data[0]['ip'];
                // // alert(totlaipp);
                // if (totlaipp != ipaddress) {
                //     localStorage.setItem('token_store_vplay');
                //     localStorage.setItem('userid');
                //     window.location.href = '/login';
                // }
                // console.warn(totlaipp);
                // setUsers(objectRes);
            })
            .catch(function (error) {
                console.log(error);
            });
        // if (!token) {
        //     window.location.href = '/login';
        //     // exist();
        // }
    }
    const loaduser = async () => {
        const token = localStorage.getItem("token_store_vplay");

        var data = '';

        var config = {
            method: 'post',
            url: 'https://api.oneentertainmentclub.com/api/users/get-transaction-history',
            headers: {
                'x-access-token': token
            },
            data: data,
            // setdata: () => {
            //     sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjczNDM1OTMxLCJleHAiOjE3MDQ5NzE5MzF9.lGDH-fUMWZpQzsOE7fD-xNQmirQM-Yvrlo3bFekhVBs')
            // }
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                // console.warn(res);
                const objectRes = JSON.parse(res);
                console.log(objectRes.data);
                // alert(objectRes.data)
                setUsers(objectRes.data);
                console.log(users[2].id);
                // config.setdata();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // ========viev======
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleOnClick = (userid) => {
        singletran(userid);
    };
    return (
        <>
            <div className="leftContainer">
                {/* <div>
                    <div className="main-area" style={{ paddingTop: '60px' }} />
                    <div className="container">
                        <a href="transaction-history-reffer" className="text-light">
                            <div className="btn btn-primary w-100 my-2 ">
                                Referral History</div>
                        </a>
                    </div>
                </div> */}
                {users > '' ?
                    users && users.map((user, index) => (
                        <>
                            <div key={index} className="w-100 py-3 d-flex align-items-center list-item">
                                <div className="center-xy list-date mx-2">
                                    <div>{user.day}{user.month}</div><small>{user.paying_time}</small>
                                </div>
                                <div className="" id="card-1">

                                    {user.payment_type == 'withdraw' && user.add_or_withdraw == 'withdraw' ?
                                        <div className="card-content">
                                            {/* {['x'].map((placement, idx) => ( */}

                                            <OffCanvasExample key={user.id} placement="z" name={user.id} onClick={() => handleOnClick(user.id)} />

                                            {/* ))} */}
                                            {/* <a href="#close" className="close" onClick={handleShow}>x</a> */}
                                            {/* <a href="#close" variant="primary" onClick={handleShow} className="me-2 close" onClick={shoot(user.id)}>x</a> */}
                                            <div className="card__info" id="showhistory" >
                                            </div>
                                        </div>
                                        :
                                        <div className="card-content">
                                            <a href="#close" className="close"></a>
                                            <div className="card__info" id="showhistory" >
                                            </div>
                                        </div>

                                    }

                                </div>
                                <div className="mx-3 d-flex list-body">
                                    <div className="d-flex align-items-center" />
                                    <div className="d-flex flex-column font-8">
                                        {user.payment_type == 'reedem' ?
                                            <span>Reedem.</span>
                                            : user.payment_type == 'frend' && user.add_or_withdraw == 'withdraw' ?
                                                <span>Transfer By Friend. {user.UseridVplay}</span>
                                                :
                                                user.payment_type == 'frend' && user.add_or_withdraw == 'add' ?
                                                    <span>Added By Friend. {user.UseridVplay}</span>
                                                    :
                                                    user.payment_type == 'withdraw' && user.add_or_withdraw == 'withdraw' ?
                                                        <><span>Withdraw in {user.withdawtype}
                                                            {user.withdraw_status == 'pending' ?
                                                                <span style={{ color: "red" }}> {user.withdraw_status}</span>
                                                                :
                                                                <span style={{ color: "green" }}> {user.withdraw_status}</span>
                                                            }
                                                        </span></>
                                                        :
                                                        user.payment_type == 'payment_online' ?
                                                            <span>Cash Add Online</span>
                                                            :
                                                            <span>Cash Add Admin</span>
                                            // : 
                                        }
                                        <div className="games-section-headline">Order ID:{user.order_id}</div>
                                    </div>
                                </div>
                                <div className="right-0 d-flex align-items-end pr-3 flex-column">
                                    <div className="d-flex float-right font-8">
                                        {user.add_or_withdraw == 'add' ?
                                            <div className="text-success">
                                                (+)
                                            </div>
                                            :
                                            <div className="text-danger">
                                                (-)
                                            </div>
                                        }
                                        <picture className="ml-1 mb-1"><img height="21px" width="21px" src={rup} alt="" /></picture><span className="pl-1">{user.amount}</span>
                                    </div>
                                    <div className="games-section-headline" style={{ fontSize: '0.6em' }}>Closing Balance: {user.closing_balance}</div>
                                </div>
                            </div>
                        </>
                    ))
                    :
                    <div className="nodata"><img width="50%" className="" src={nogame} alt="" />
                        <h2 className="text-center" style={{ color: "#feb946" }}>No Transaction yet!</h2>
                        <p className="text-center" style={{ color: "#000" }}>You have no transaction history yet.</p>
                    </div>
                }


                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Body> <div className="card-content">
                        <a href="#close" className="close" onClick={handleClose}>x</a>
                        <div className="card__info" id="showhistory">
                            <div>
                                <h3 style={{ paddingTop: '2%' }}>Withdraw</h3>
                                <hr />
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Order ID</strong></div>
                                    {/* <div className="col-md-6">order_{user.order_id}</div> */}
                                    <div className="col-md-6">order_769445</div>
                                </div>
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Request Time</strong></div>
                                    {/* <div className="col-md-6">{user.day}-{user.month}-{user.year} {user.paying_time}</div> */}
                                    <div className="col-md-6">02-04-2022 04:40 pm</div>
                                </div>
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Processed Time</strong></div>
                                    {/* <div className="col-md-6">{user.day}-{user.month}-{user.year} {user.paying_time}</div> */}
                                    <div className="col-md-6">02-04-2022 04:40 pm</div>
                                </div>
                                <div className="row align-items-center" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Status</strong></div>
                                    <div className="col-md-6"><button className="btn btn-success py-0">PAID</button></div>
                                </div>
                                <br />
                                <h5>Payment Details</h5>
                                <div className="row pb-3" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-4">UPI</div>
                                    <div className="col-md-8">2200</div>
                                </div>
                            </div>
                        </div>
                    </div></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        </>

    );
}

export default Transactionhistory;