
import React, { useState, useEffect } from "react";
import loginimg from '../images/global-gameSheetSplash.png';
import axios from "axios";
// import history from './history'
import logo from '../images/kkhelchampian.png';
import { useNavigate } from "react-router-dom";
import { Outlet, Link, Await, } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';
import loader from '../images/dancingloader.gif'
import withReactContent from 'sweetalert2-react-content';
import Rightsidebar from '../routes/Rightsidebar';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
// import Newdashbord from "./Newdashbord";
//  import  Term from "../routes/Term"

// const jwt = require('jsonwebtoken');

// let user;

// Otp Api 

// import PWAInstallerPrompt from 'react-pwa-installer-prompt';
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
const Login = () => {
  sessionStorage.setItem("token", "");
  sessionStorage.setItem("userid", "");

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(true);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [browserId, setBrowserId] = useState('');
  useEffect(() => {
    const userid = localStorage.getItem("userid");

    if (userid ) {
      window.location.href = '/dashbord';
    }
    let browserIdd = Cookies.get('browserId');

    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set('browserId', browserIdd, { expires: 20 }); // Store the ID in a cookie that expires in 365 days
    }
    setBrowserId(browserIdd)
    // alert(browserIdd);


    // console.log('Browser ID:', browserIdd);
  }, []);

  useEffect(() => {
    // Event listener for beforeinstallprompt
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    // Add the event listener
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    // Cleanup: Remove the event listener
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {

    const isAppInstalled = localStorage.getItem('isAppInstalled');

    if (isAppInstalled) {
      setShowInstallButton(false);
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user's choice
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // User accepted the A2HS prompt
          console.log('User accepted the A2HS prompt');

          // Mark the app as installed
          localStorage.setItem('isAppInstalled', 'true');
          setShowInstallButton(false);
        } else {
          // User dismissed the A2HS prompt
          console.log('User dismissed the A2HS prompt');
        }

        // Reset the deferredPrompt
        setDeferredPrompt(null);
      });
    }
  };


  const [user, setUserts] = useState({
    mobile: "",
  });

  const [motp, setMotp] = useState(
    {
      mobile: "9782950745",
      otp: "1234"
    }
  )
  // const token =  user.generateAuthToken();
  const [otpsand, setOtpsend] = useState(null);
  const [isShown, setIsShown] = useState(false);

  const [mobile, mobilenumber] = useState();
  const [otpnumbers, otpnumber] = useState();


  // const { mobile } = user;
  function onInputChange(e) {
    // alert(e.target.value);
    mobilenumber(e.target.value);
    // setUserts({ ...user, [e.target.name]: e.target.value });
  };
  function onInputChangeotp(e) {
    otpnumber(e.target.value);
  };
  useEffect(
    () => {
      getData();
    }, []
  );
  const [ipset, setIP] = useState();
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // alert(res.data.IPv4);
    setIP(res.data.IPv4);
    // localStorage.setItem('ipaddress', res.data.IPv4);
  }
  const onSubmit = async (e) => {
    e.preventDefault();

    let res = axios.post(`${process.env.REACT_APP_API_URL_NODE}/api/users/send-otp`, {
      method: "POST",
      mobile: mobile
    }).then((response) => {
      if (response.data.status) {
        setIsShown(true)
      } else {
        setIsShown(false);
      }
      // const phoneno = /^\d{10}$/;
      //     if ((mobile.mobile.match(phoneno))) {


      //     }
      //     else {
      //         setIsShown(false);

      //     }
    });
    //   setIsShown(true)
  }
  // =====Login Api=====

  const navigate = useNavigate();
  const loginverfiy = async (e) => {
    e.preventDefault();
    const queryParameters = new URLSearchParams(window.location.search);
    const type = queryParameters.get("reffercode");
    if (type) {
      var reffercode = type;
    } else {
      var reffercode = '';
    }
    // alert(ipset);
    let res = axios.post(`${process.env.REACT_APP_API_URL_NODE}/api/users/user-register`, {
      method: "POST",
      mobile: mobile,
      otp: otpnumbers,
      reffercodeGiven: reffercode,
      ipaddress: browserId,
    }).then((response) => {
      if (response.data.status == 1) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: <strong>Login Successfully</strong>,
          icon: 'success',
          timer: 1500
        }).then((result) => {
          localStorage.setItem('token_store_vplay', response.data.token);
          localStorage.setItem('userid', response.data.userid);
          localStorage.setItem('ipaddress', browserId);
          window.location.href = '/dashbord';
        })
      } else {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: <strong>{response.data.message}</strong>,
          icon: 'error',
          timer: 1500
        }).then((result) => {

        })
      }
    });
  }
  function OffCanvasExample({ name, ...props }) {
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function handleShownew(breakpoint) {
      setShow(breakpoint);

      setFullscreen(true);
    }
    return (
      <>
        <div id="myOffcanvas" class="offcanvas" tabindex="-1" aria-labelledby="offcanvasLabel">
          <div class="offcanvas-header">
            <h5 class="text-white border-bottom-custum" id="offcanvasLabel">Add To Home Screen</h5>
            <div class="col-md-2 cancelbtn" onclick="handleShownew(false)">Cancel</div>
          </div>
          <div class="offcanvas-body text-white">
            <p class="text-center text-white content-pwa">
              This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.
            </p>
            <div>
              <ul class="list-style-none">
                <li class="d-flex"><i class="bi bi-box-arrow-up arrowcolor"></i><span>1 Press the 'Share' button</span></li>
                <li><i class="bi bi-plus-square"></i><span>2 Press 'Add to Home Screen'</span></li>
              </ul>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn-primary pwabtn " onclick="handleShow()">
          {name}
        </button>

      </>
    );
  }

  return (
    <>
      <div className="leftContainer">
{/*
        <div className="headerContainer">
          <Link to='/login'>
            <picture className="ml-2 navLogo d-flex">
              <img src={logo} alt="logo" style={{ height: '55px', width: '70px' }} />
            </picture>
          </Link>
          <div className="menu-items">
           <Link type="button" className="login-btn" to="/login">LOGIN</Link>
          </div>
          <span className="mx-5" />
        </div>
*/}
        {/* <div className="main-area"> */}
        <div style={{ overflowY: 'hidden' }} >
          <div className="splash-overlay" />
          <div className="splash-screen">
            <figure><img width="100%" src={loginimg} alt="" /></figure>
          </div>
          <form method="post" id="user_login" onSubmit={onSubmit}>
            <input type="hidden" name="_token" defaultValue="C7QRdqspHb1mwSzxeM2NJd9raXKwU7i4EfmBBHZ6" /> <input type="hidden" name="reffercode" id="reffercode" defaultValue />
            <div className="loginform">
              <div className="d-flex text-white font-15 mb-4 justify-content-center">Sign in or Sign up</div>
              <div className="bg-white px-4 cxy flex-column pt-4" id="incheight">
                <div id="sign_up_success" />
                <div className="input-group" style={{ transition: 'top 0.5s ease 0s' }}>
                  <div className="input-group-prepend">
                    <div className="input-group-text" style={{ width: '80px' }}>+91</div>
                  </div>

                  <input className="form-control" required pattern="[0-9]{10}" name="mobile" id="mobile" type="tel" placeholder="Mobile number" value={mobile} style={{ transition: 'all 3s ease-out 0s' }} onChange={onInputChange} />

                  <div className="invalid-feedback">Enter a valid mobile number</div>
                </div>
                {/* <span classname="btn btn-info" id="sendotp" style={{marginLeft: '74%'}}>Send Otp</span> */}
                <br />
                {isShown ? (

                  <div className="input-group pt-2 otp mb-3" style={{ transition: 'left 0.5s ease 0s' }}>
                    <div className="input-group-prepend">
                      <div className="input-group-text" style={{ width: '80px' }}>OTP</div>
                    </div>
                    <input className="form-control" name="otp" id="otp" type="tel" onChange={onInputChangeotp} placeholder="Enter OTP" autoComplete="off" />
                    <div className="invalid-feedback">Enter a correct OTP</div>
                  </div>

                ) : null}

              </div>

              {isShown ? <button type="button" onClick={loginverfiy} className=" w-100 bg-green refer-button cxy mt-4 otp_login submit_data" id="submit_data" name="submit_data">Login</button> : <button type="submit" className="bg-green refer-button cxy mt-4 send-otp w-100 " id="send_ottp">Send OTP</button>}

            </div>
          </form>

          <div className="login-footer">
            <div className="d-flex justify-content-center mb-3">
              {isIOS && (
                <>
                  <div className="d-flex jusitfy-content-center">
                    {['Install Web Application IOS'].map((placement, idx) => (
                      <OffCanvasExample key={idx} placement={placement} name={placement} />

                    ))}
                  </div>
                </>

              )}

              {!isIOS && showInstallButton && (
                <button className="btn btn-ronded text-white pwabtn d-block" onClick={handleInstallClick}>Install Web Application</button>
              )}
            </div>
            By proceeding, you agree to our
            <Link to="/Term">Terms of Use ,</Link>
            <Link to="/Privacy">Privacy Policy</Link>


            {/* <a href="term-condition">Privacy Policy</a>  */}
            and that you are 18 years or older. You are not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.</div>
          {/* </div> */}

        </div>
      </div>
      <Rightsidebar />
      <Outlet />
    </>
  );
}
export default Login;



//     ('#submit_data').hide();
//     ('.otp').hide();
// });
// (document).on('click', '#send_ottp', function () {
//     mobile = ('#mobile').val();
//     if (mobile.length == 10) {
//         $.ajax({
//             headers: {
//                 'X-CSRF-TOKEN': ('meta[name="csrf-token"]').attr('content')
//             },
//             url: BASE_URL + '/otpsend',
//             type: 'post',
//             data: {
//                 'mobile': $('#mobile').val()
//             },
//             success: function (data) {
//                 // alert(data);
//                 //if(data != 0){
//                 if (data.status) {
//                     $('#send_ottp').hide();
//                     $('.otp').show();
//                     $('#submit_data').show();
//                     var successContent = "<div id='success-alert' class='alert alert-success'>OTP Send Mobile Number</div>";
//                     $("#sign_up_success").html(successContent);
//                     $("#success-alert").fadeTo(2000, 1000).slideUp(1000, function () {
//                         $("#success-alert").slideUp(500);
//                         // location.reload();


//                     });
//                 }

//             },
//             error: function () {
//                 console.log('error');
//             }
//         });
//     } else {
//         var successContent = "<div id='success-alert' class='alert alert-danger'>Mobile Number Must be 10 Digit</div>";
//         $("#sign_up_success").html(successContent);
//         $("#success-alert").fadeTo(2000, 1000).slideUp(1000, function () {
//             $("#success-alert").slideUp(500);
//             // location.reload();

//         });
//     }
// });