import React from 'react'

export default function Aboutus() {
  const ludoname = "Badshah Ludo";
  return (
    
    <div>   
      <div className='leftContainer' style={{padding:"15px", paddingTop:"20px"}}>
      <h2 className='heading_design'>About Us</h2>
      <h6 className="dashboard_paragraph">1.{ludoname} is a real-money & Skill Based gaming product owned and operated by {ludoname}
</h6>
<h6 className="dashboard_paragraph">2.Our Business & Products</h6>
<h6 className="dashboard_paragraph">3.We are an Skill Based game-publishing company and our mission is to make accessing Web Based games fast and easy by removing the friction of app-installs.
</h6>

<h6 className="dashboard_paragraph">4.{ludoname}  is a skill-based real-money gaming platform accessible only for our users in India. It is accessible on https://Badshahludo.com On {ludoname}, users can compete for real cash in Tournaments and Battles. They can encash their winnings via popular options such as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.</h6>
<h6 className="dashboard_paragraph">5.Our Games
{ludoname} has a wide-variety of high-quality, premium Web Based Games. Our games are especially compressed and optimised to work on low-end devices, uncommon browsers, and patchy internet speeds.</h6>
<h6 className="dashboard_paragraph">6.We have games across several popular categories: Arcade, Action, Adventure, Sports & Racing, Strategy, Puzzle & Logic. We also have a strong portfolio of multiplayer games such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our popular titles are: Escape Run, Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends. If you have any suggestions around new games that we should add or if you are a game developer yourself and want to work with us, don't hesitate to drop in a line at mail ID : Badshahludogame@outlook.com</h6>
<h6 className="dashboard_paragraph">7.Skill-based gaming has several benefits</h6>
<h6 className="dashboard_paragraph">8.Skill-based gaming has several benefits. It can help you become more self-aware, learn more about your strengths and weaknesses, and become more comfortable taking risks 1. Skill-based games are also a great way to improve your cognitive processing, mental health, social skills, and decision-making capabilities</h6>

   
   
      </div>
      
       
      
    </div>
  )
}
