// import React, { useState } from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import wp from '../images/wp.png'

export default function Footer() {

  const [active, setActive] = useState("Home");

  const handleLinkClick = (linkName) => {
    setActive(linkName);
  };

  const links = [
    { name: "Home", icon: "bi bi-house-door-fill", to: "/" },
    { name: "Wallet", icon: "bi bi-wallet2", to: "/add-fund" },
    { name: "People", icon: "bi bi-people", to: "/refer-earn" },
    { name: "User", icon: "bi bi-person", to: "/profile" },
    { name: "Support", icon: "bi bi-telephone", to: "/Support" }
  ];

  return (
    <div id="leftContainer">
      <section id="footer">

        <ul className="footer-link">
          {links.map((link) => (
            <li key={link.name} className={active === link.name ? "activemenu" : ""}>
              <Link to={link.to} onClick={() => handleLinkClick(link.name)}>
                <i className={link.icon}></i>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

      </section>
      <div class="support_samll_card_btn_type whatsap_btn">
        <a href="//api.whatsapp.com/send?phone=9660056658&text=" target="_blank">
          <img width="40px" src={wp} alt="" /></a>
      </div>

    </div>
  );
}
