import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import vs from '../images/versus.png'
import user1 from '../images/Avatar2.png'


import rupeeIcon from '../images/global-rupeeIcon.png'
import loader from '../images/dancingloader.gif'
import ios from '../images/ios.png'
import android from '../images/android.jpg'
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import $ from 'jquery'; 
const RoomCode = () => {
  const [gamebat, setGamebat] = useState(
    {
        game_url: "Ludo-Classic",
        amount: '',
        label: ""
    }
)
const [gameid, setgameid] = useState({

    "game_id": "1"
})

  const [users, setUsers] = useState([]);
  const [roomcode, setRoomCode] = useState([]);
  const [battleId, setBattleId] = useState('null');
  const [GameeNamee, setGameeNamee] = useState('null');
  const [joinerId, setJoinerId] = useState('null');
  const [createrId, setCreatorId] = useState('null');
  const [creatorresult, setCreatorResult] = useState('');
  const [joinerresult, setJoinerResult] = useState('');
  const [BatAmount, setBatAmount] = useState('');
  const [CretaerName, setCreaterName] = useState('');
  const [JoinerName, setJoinerName] = useState('');


  // alert(joinerId);

  useEffect(() => {
    const segment = window.location.pathname.split("/");
    setGameeNamee(segment[1]);
    console.warn(segment[1])
    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
    // alert(idParam);
    if (idParam) {
      setBattleId(idParam);
    }
  }, []);

  const oninputchange = (e) => {
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
}
  
  useEffect(
    () => {
      loaduser();
      $('.loader_roomcode').hide();
      $('.overlay_div').hide();
      // $('.profile-pic').hide();
    }, []
  );


  const loaduser = async () => {
    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
   
    // console.warn(idParam);
    const token = localStorage.getItem("token_store_vplay");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/room-code-dta`,
      headers: {
        "x-access-token": token
      },
      data: {id:idParam}
    };

    try {
      const response = await axios(config);
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes.data);

      if (objectRes.data.length > 0) {
        setRoomCode(objectRes.data[0].LOBBY_ID);
        setJoinerId(objectRes.data[0].joiner_id);
        setCreatorId(objectRes.data[0].creator_id);
        setCreatorResult(objectRes.data[0].creator_result);
        setJoinerResult(objectRes.data[0].joiner_result);
        setBatAmount(objectRes.data[0].amount);
        setCreaterName(objectRes.creater_data[0].vplay_id);
        setJoinerName(objectRes.joiner_data[0].vplay_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [fileData, setFileData] = useState('null');
  const [selectedOption, setSelectedOption] = useState('');
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('false');
  const player_id = localStorage.getItem("userid");

  useEffect(
    () => {
      // document.getElementById('canceldata').removeAttribute("style");
      document.getElementById('canceldata').style.display = 'none';
    }, []
  );
//   $(".file-upload1").on('change', function(){
//     alert('pppp');
//     readURL(this);
// });
// $(".upload-button").on('click', function() {
//   $(".file-upload1").click();
// });
// var readURL = function(input) {
//   if (input.files && input.files[0]) {
//       var reader = new FileReader();

//       reader.onload = function (e) {
//         $('.profile-pic').show();
//           $('.profile-pic').attr('src', e.target.result);
//       }

//       reader.readAsDataURL(input.files[0]);
//       // setFileData(input.files[0]);
//       // profileupdate(input.files[0]);
//   }
// }

  useEffect(
    () => {
      loaduser();

    
        var readURL = function(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
    
                reader.onload = function (e) {
                  // $('.profile-pic').show();
                    $('.profile-pic').attr('src', e.target.result);
                }
        
                reader.readAsDataURL(input.files[0]);
                // setFileData(input.files[0]);
                // profileupdate(input.files[0]);
            }
        }
        
    
        $(".file-upload1").on('change', function(){
            readURL(this);
        });
        
        $(".upload-button").on('click', function() {
           $(".file-upload1").click();
        });
   
    }, [true]
  );
  const handleOptionChange = (e) => {
    const value = e.target.value;
    // alert(value);
    if(value == 'cancel'){
      // var x = document.getElementById("canceldata");
      // if (x.style.display === "none") {
      //   x.style.display = "block";
      // } else {
      //   x.style.display = "none";
      // }
      $('#canceldata').show();
          // var settlement_sidebar = document.getElementById("canceldata");
      // settlement_sidebar.classList.remove("mm-active");
      // var checkedValue = $('#exampleFormControlTextarea1:checked').val();
      // var checkedValue = document.querySelector('#exampleFormControlTextarea1:checked').value;
      // alert(checkedValue);
      // document.getElementById('canceldata').style.display = 'block';
    }else if(value == 'win'){
      $('#canceldata').hide();
      $('.profile-pic').show();
    }else{
      // alert('cfvgv');
      $('#canceldata').hide();
      // $('.profile-pic').hide();
    }
    setSelectedOption(value);
    setShowFileUpload(value === "win");
    setShowMessage(value === "lost");
    setMessage(value === "cancel");
    setFileData(null);
  };

  // const handleTextareaChange = (e) => {
  //   setMessage(e.target.value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // req.files.image.mimetype
    // console.warn(fileData);
   
    if(fileData == null){
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: <strong>Please Upload Screenshot!</strong>,
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 7000,

      }).then((result) => {
        // window.location.href = "/";
        // location.href = "/";
      })
    }else{
      $('.loader_roomcode').show();
      $('.overlay_div').show();
      document.getElementById('disablehandleSubmit').style.display = 'none';
      if (!selectedOption) {
        console.error("Please select one of the options (I won, I lose, or Cancel)");
        return;
      }
      // return;

      const apiUrl = `${process.env.REACT_APP_API_URL_ADMIN}/battle-result-store-react`;
      const formData = new FormData();
      formData.append('image', fileData);
      formData.append('battleResult', selectedOption);
      formData.append('battleId', battleId);
      formData.append('userid', player_id);
  
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "no-cors",
          body: formData,
        }).then((response) => {
          // if (response.data.message == true) {
            const MySwal = withReactContent(Swal)
            MySwal.fire({
              title: <strong>Declear Successfully!</strong>,
              icon: 'success',
              showCloseButton: true,
              showCancelButton: false,
              showConfirmButton: false,
              timer: 7000,
    
            }).then((result) => {
              window.location.href = "/";
              // location.href = "/";
            })
          // } else {
          //   const MySwal = withReactContent(Swal)
          //   MySwal.fire({
          //     title: response.data.message,
          //     icon: 'error'
          //   })
          // }
        });
  
       
      } catch (error) {
        console.error("An error occurred while making the API request:", error);
  
      }
    }
   
    // const FormData = require('form-data');
    // let data = new FormData();
    // data.append('id', '11');

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'http://fashionvidhya.com/Admin/public/battle-result-store-react',
    //   headers: { 
    //     'Cookie': 'XSRF-TOKEN=eyJpdiI6Im1tU1RWaGVOcjlDYm12QjA4ZmJqNkE9PSIsInZhbHVlIjoiVEVON1pZSlloUi9pbytZbFpLT1gxMFFGbktTcUdFTzlwaU45TG1YOTdHNm9iWmwrS3I4RkZzRjh0MEdhbzFwb2tVcW5YcVhHa2U5dmNRTlZpMGdmbHVQVmRGVXZpdUJoZ001TCs1NHQrdTR4a3pkR0RRRDBUa3NWd0dQTTkyZVEiLCJtYWMiOiI3YTBmYjZkNjYzNzBiYzM3MzJjMjYxZGJmNTZkM2E0YjI3NjIwYTM3YjgzZTQ0Y2ZiYzE3Y2M0MDc0NzBhZTQ1In0%3D; vplay_session=eyJpdiI6ImovbzNuZkxiVDhFbWNtMjZPYTlQbkE9PSIsInZhbHVlIjoic3puRzhtcXBadnorUTd6Q2xMTEkwK09FTDd2NjZ3REJjY1o3QmVOMWR2WDVjQmozMUY4TUgrRGlSb2VPdUdySWp2dkQ5SEhmYTZTOGVCWmRRWlAxQWxhL3A2OWM3Nlg5MG1VRXZQKzZBUXdBdXFEVThRUnAyRkNsZDBDU0JNN2siLCJtYWMiOiIyMDE5NDQ0ZWE1ZmQyNzk5NmQwMzE5N2I3ZThiMDc2YjJmNzgzZDY2ZDQ1YzhhOGEyNjIwMGZlOWMwNTU5ODk1In0%3D', 
    //     ...data.getHeaders()
    //   },
    //   data : data
    // };

    // axios.request(config)
    // .then((response) => {
    //   alert(response);
    //   console.warn(JSON.stringify(response.data));
    // })
    // .catch((error) => {
    //   console.log(error);
    // });

  };


  const handleSubmit1 = async (e) => {
    e.preventDefault();
    document.getElementById('disablehandleSubmit1').style.display = 'none';
    // alert(battleId);
    $('.loader_roomcode').show();
    $('.overlay_div').show();
    if (!selectedOption) {
      console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;
    }
    const apiUrl = `${process.env.REACT_APP_API_URL_ADMIN}/battle-result-store-react`;
    const formData = new FormData();
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('userid', player_id);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }).then((response) => {
        // if (response.data.message == true) {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Declear Successfully!</strong>,
            icon: 'success',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 7000,
  
          }).then((result) => {
            
            window.location.href = "/";
          })
       
      });
    } catch (error) {
      console.error("An error occurred while making the API request:", error);

    }
  };


  const handleSubmit2 = async (e) => {
    e.preventDefault();
    
    
    // alert(message);
    if(message == true){
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: <strong>Please Enter message!</strong>,
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 7000,

      }).then((result) => {
        
        // window.location.href = "/";
      })
      return;
    }
    document.getElementById('disablehandleSubmit2').style.display = 'none';
    $('.loader_roomcode').show();
    $('.overlay_div').show();
    if (!selectedOption) {
      console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;
    }
    const apiUrl = `${process.env.REACT_APP_API_URL_ADMIN}/battle-result-store-react`;
    const formData = new FormData();
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('userid', player_id);
    formData.append('cancelMessage', message);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }).then((response) => {
        // if (response.data.message == true) {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Declear Successfully!</strong>,
            icon: 'success',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 7000,
  
          }).then((result) => {
            
            window.location.href = "/";
          })
       
      });
    } catch (error) {
      console.error("An error occurred while making the API request:", error);

    }
  };



  const handleCopyClick = () => {
    const textField = document.createElement('textarea');
    textField.innerText = roomcode;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    alert('Room code copied to clipboard!');
  };


  return (
    <>
      <div className="container"></div>
     
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">
    
            <div className="text-center">
              <div className="roomcode">  
                <div className="d-flex justify-content-center width_300">
                  <div className="user1">
                    <img className="img-fluid" src={user1} style={{width:"50px"}} />  
                 <span> {CretaerName}</span>
                  </div>
                  <div className="vs">
                    <img src={vs} className="img-fluid" />
                  </div>
                  <div className="user2">
                    <img className="img-fluid" src={user1} style={{width:"50px"}} />
                   <span>{JoinerName} </span> 
                  </div>
                </div>
                <div className="amountplay">
                  <p className="playing"><span style={{color:"black",opacity:"0.8"}}>Playing for </span><img src={rupeeIcon} className="img-fluid money_width" /><span  className="batamount"> {BatAmount}</span></p>
                </div>

              </div>
              <hr></hr>

              <div className="codebg">
                <h5>Room Code</h5>
                <h6>{roomcode}</h6>
                <button className="btn btn-green btn_copy" onClick={handleCopyClick}>
                  Copy Here
                </button>
              </div>

              <div className="luodking">
                <p>Play ludo game in Ludo King App</p>
                <div className="d-flex jusitfy-content-center anroid_icon">
                  <div className="iosandroidimage">
                    <img src={android} className="img-fluid android" />
                  </div>
                  <div className="iosandroidimage">
                    <img src={ios} className="img-fluid android" />
                  </div>
                </div>
              </div>
              <button className="bg-green playButton position-static mt-2" onClick={handleCopyClick}>Copy Code</button>
            </div>

          </div>
        </div>
      </div>
      <div className="width_status">
        <div class="rules"><span class="cxy mb-1"><u>Game Rules</u></span>
          <ol class="list-group list-group-numbered">
            <li class="list-group-item">Record every game while playing.</li>
            <li class="list-group-item">For cancellation of game, video proof is necessary.</li>
            <li class="list-group-item">50 Penality will be charged for updating wrong result.</li>
            <li class="list-group-item">25 Penality will be charged for not updating result.</li>
          </ol>
        </div>
      </div>

      <div className="width_status position-relative">
     
        <div className="match-status-border row">
          <div className="matchstaus" style={{ fontSize: 20, fontWeight: 800 }}>Match Status</div>
          <div className="border_width_room">
          {joinerId == player_id && joinerresult != null ? 
            <div className="text-center">
                  <p>Result All Ready declare {joinerresult}</p>
            </div> 
          : createrId == player_id && creatorresult != null ?  
            <div className="text-center">
            <p>Result All Ready declare {creatorresult}</p>
            </div>  
          : <form className="form-room text-center">
          <label className="radio-inline">
            <input type="radio" value="win" checked={selectedOption === "win"} onChange={handleOptionChange} /> 
            {/* {GameeNamee == 'Ludo-Ulta'?
              <span> Lost</span>
            :   */}
            <span>I Won</span>
          {/* } */}
           
             </label>
          <label className="radio-inline">
            <input type="radio" value="lost" checked={selectedOption === "lost"} onChange={handleOptionChange} />
            
             {/* {GameeNamee == 'Ludo-Ulta'?
              <span> I Won</span>
            :   */}
            <span>Lost</span>
          {/* } */}
             </label>
          <label className="radio-inline">
            <input type="radio" value="cancel" checked={selectedOption === "cancel"} onChange={handleOptionChange} />
            Cancel
          </label>

          {/* File Upload */}
          {showFileUpload && (
            <>
              <div className="file_upload">
                <label> <input type="file" className="file-upload1" onChange={(e) => setFileData(e.target.files[0])} /></label>
                {/* <label> <input type="file" className="file-upload1"/></label> */}
              </div>
              <br /><br />
                <img className="profile-pic" height="200px" width="200px"/>
              <br /><br />
              <div className="btn_details">
                <button type="submit" className="btn btn-green sbumitbuttondis" id="disablehandleSubmit" onClick={handleSubmit}>
                  Submit Details
                </button>
              </div>
            </>

          )}

          {/* Show message */}
          {showMessage && (
            <>
              <div className="text-center">
                <p>Better luck next time!</p>
              </div>
              <div className="btn_details">
                <button type="submit" className="btn btn-green sbumitbuttondis" id="disablehandleSubmit1" onClick={handleSubmit1}>
                  Submit Details
                </button>
              </div>
            </>

          )}

        {message && (
            <div id="canceldata">
              <div className="text-center">
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setMessage(e.target.value)}  ></textarea>
              </div>
              <br/>
              <div className="btn_details">
                <button type="submit" className="btn btn-green sbumitbuttondis" id="disablehandleSubmit2" onClick={handleSubmit2}>
                  Submit Details
                </button>
              </div>
            </div>

          )}


         


         
        </form>}
            {/* {joinerId == player_id && joinerresult != null ? (
              <div className="text-center">
                <p>Result All Ready declare {joinerresult}</p>
              </div>
            ) : (
             
              <form className="form-room text-center">
                <label className="radio-inline">
                  <input type="radio" value="win" checked={selectedOption === "win"} onChange={handleOptionChange} /> I won </label>
                <label className="radio-inline">
                  <input type="radio" value="lost" checked={selectedOption === "lost"} onChange={handleOptionChange} /> I lose </label>
                <label className="radio-inline">
                  <input type="radio" value="cancel" checked={selectedOption === "cancel"} onChange={handleOptionChange} />
                  Cancel
                </label>

                {showFileUpload && (
                  <>
                    <div className="file_upload">
                      <label> <input type="file" onChange={(e) => setFileData(e.target.files[0])} /></label>
                    </div>
                    <br /><br />
                    <div className="btn_details">
                      <button type="submit" className="btn btn-green" onClick={handleSubmit}>
                        Submit Details
                      </button>
                    </div>
                  </>

                )}

                {showMessage && (
                  <>
                    <div className="text-center">
                      <p>Better luck next time!</p>
                    </div>
                    <div className="btn_details">
                      <button type="submit" className="btn btn-green" onClick={handleSubmit1}>
                        Submit Details
                      </button>
                    </div>
                  </>

                )}

               
              </form>

            )} */}
          </div>
        </div>
        <div>
        <div className="overlay_div"></div>
        <div className="loader_roomcode">
      <img src={loader}></img>
      </div>
        </div>
      </div>

    </>
  );
};
export default RoomCode;