import React, { useEffect, useState } from "react";
import axios from "axios";
import logos from '../images/logo.png'
import chrome from '../images/global-chrome.png'

function Rightsidebar() {
  const ludoname = "Badshah Ludo";

  const [users, setUsers] = useState([]);
  const userid = localStorage.getItem("userid");
  // alert(userid)
const [Whatsa, setWhatsa] = useState([]);
useEffect(
() => {
  loaduser();
}, []
);
const loaduser = async () => {
  const formData = new FormData();
  formData.append('user_id', userid);

  const token = localStorage.getItem("token_store_vplay");

  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL_ADMIN}/api/common.php`,
    data: formData,
    headers: {
     
    }
  };

  axios(config)
    .then(function (response) {
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes.contactNumber);
      setWhatsa("https://api.whatsapp.com/send?phone=9660056658&text=Hello");
      // setWhatsa("https://api.whatsapp.com/send?"+"6376900391");
    })
    .catch(function (error) {
      console.log(error);
    });
};

// const loaduser = async () => {


// var data = '';

// const token = localStorage.getItem("token_store_vplay");

// // var config = {
// //   method: 'post',
// //   url: 'https//oneentertainmentclub.com/Admin/public/api/common.php',
// //   user_id: userid,
// // };
// var config = {
//   method: 'post',
//   url: 'https://oneentertainmentclub.com/Admin/public/api/common.php',
  
//   data: {
 
//     user_id: userid
//   }
//   const formData = new FormData();
//   formData.append('image', fileData);
//   headers: {
//     // Authorization: `Bearer ${token}` s
//   }
// };

// axios(config)
//   .then(function (response) {
//     const res = JSON.stringify(response.data.data);
//     const objectRes = JSON.parse(res);
//     // alert(objectRes.contactNumber);
//     setUsers(objectRes.contactNumber);
//     setWhatsa("https://api.whatsapp.com/send?Hello="  + objectRes.contactNumber);

//   })
//   .catch(function (error) {
//     console.log(error);
//   });
    
//   }
    return (
        <div className="">
            <div className="divider-y" />
            <div className="rightContainer bg_right">
                <div className="rcBanner flex-center">
                    <picture className="rcBanner-img-container">
                        <img src={logos} alt="" title="Vplay Ludo" width="100px" hight="20px" />
                    </picture>
                    <div className="rcBanner-text">{ludoname} <span className="rcBanner-text-bold">Win Real Cash!</span></div>
                    <div className="rcBanner-footer">Developed by chrome&nbsp;<a href="#!" style={{ color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none' }}></a><img src={chrome} alt="" />&nbsp;chrome mobile</div>
                </div>
            </div>
            {/* <a class="download-foating-btn whatsup" href={Whatsa} target="_blank"><span><i class="bi bi-whatsapp mr-1"></i>WhatsApp</span></a> */}
        </div>
    );
}
export default Rightsidebar;