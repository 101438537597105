import React from "react";
import logo from '../images/vplaylogo1.png'
import logos from '../images/vplaylogo1.png'
import logo1 from '../images/game-image.jpg'
import logo2 from '../images/snakeandladder.jpg'
import logo3 from '../images/global-battleIconWhiteStroke.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Outlet,Link } from "react-router-dom";



const Index = () => {
    // alert('ppppp');
    return (
        <>

            <div class="leftContainer">
                <div className="headerContainer">
                    <a href="index.html">
                        <picture className="ml-2 navLogo d-flex">
                            <img src={logo} alt="logo" style={{ height: '55px', width: '70px' }} />
                        </picture>
                    </a>
                    <div className="menu-items">
                        <Link type="button" className="login-btn" to="/login">LOGIN</Link>
                    </div>
                    <span className="mx-5" />
                </div>
                <div className="main-area" style={{ paddingTop: '60px' }}>
                    <div className="collapseCard-container">
                
                        <div className="collapseCard">
                            <h5>V play</h5>
                        </div>
                    </div>
                </div>

                <section className="games-section p-3">
                    <div className="d-flex align-items-center games-section-title">Our Games</div>
                    <div className="games-section-headline mt-2 mb-1">
                        <div className="games-window">
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                <Link className="gameCard" to="/login">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Classic</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                <Link className="gameCard" to="/login">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Popular</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                <Link className="gameCard" to="/login">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder Sidha</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                <Link className="gameCard" to="/login">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder Ulta</div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="footer">
                    <div className="footer-divider" />

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"

                                >
                                    <Typography>

                                        <a className="px-3 py-4 d-block" href="#!" style={{ textDecoration: 'none' }}>
                                            <picture className>

                                                <img src={logos} alt="" width="100px" hight="20px" />
                                            </picture>
                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}> . Terms, Privacy,
                                                Support</span><br />

                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}><strong>The Game is
                                                ON</strong></span>
                                        </a>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="row footer-links">
                                            <Link className="col-6" to="/login">Terms &amp; Condition</Link>
                                            <Link className="col-6" to="/login">Privacy Policy</Link>
                                            <Link className="col-6" to="/login">Refund/Cancellation Policy</Link>
                                            <Link className="col-6" to="/login">Contact Us</Link>
                                            <Link className="col-6" to="/login">Responsible Gaming</Link>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="footer-divider" />
                        <div className="px-3 py-4">
                            <div className="footer-text-bold">About Us</div><br />
                            <div className="footer-text">khel champion.bet is a real-money gaming product owned and operated by
                                Gamicbond.pvt.ltd ("khel champion" or "We" or "Us" or "Our")..</div><br />
                            <div className="footer-text-bold">Our Business &amp; Products</div><br />
                            <div className="footer-text">We are an HTML5 game-publishing company and our mission is to make
                                accessing games fast and easy by removing the friction of app-installs.</div><br />
                            <div className="footer-text">khel champion is a skill-based real-money gaming platform accessible only
                                for our users in India. It is accessible on <Link to="/">https://www.totalludo.com</Link> Onkhel champion, users can compete for real
                                cash in Tournaments and Battles. They can encash their winnings via popular options such
                                as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc. </div><br />
                            <div className="footer-text-bold">Our Games</div><br />
                            <div className="footer-text">khel champion has a wide-variety of high-quality, premium HTML5 games. Our
                                games are especially compressed and optimised to work on low-end devices, uncommon
                                browsers, and patchy internet speeds. </div><br />
                            <div className="footer-text">
                                We have games across several popular categories: Arcade, Action, Adventure, Sports &amp;
                                Racing, Strategy, Puzzle &amp; Logic. We also have a strong portfolio of multiplayer games
                                such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers
                                and more! Some of our popular titles are: Escape Run, Bubble Wipeout, Tower Twist,
                                Cricket Gunda, Ludo With Friends. If you have any suggestions around new games that we
                                should add or if you are a game developer yourself and want to work with us, don't
                                hesitate to drop in a line at khelchampion@outlook.com
 !
                            </div>
                        </div>
                    </div></section>

            </div>


            <Outlet />
        </>

    );
}

export default Index;