

import React, { useEffect, useState } from "react";
import right from '../images/right.png';
import axios from "axios";
import { ConstructionOutlined } from "@mui/icons-material";
import { display } from "@mui/system";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import nogame from "../images/nonotification.png";


const Notification = () => {

  const [users, setUsers] = useState([]);
  const [nd, setNd] = useState([]);


  useEffect(
    () => {
      loaduser();
      // DeleteUser();
      // alert('game')

    }, []
  );

  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");

    var data = '';

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/notification-list`,
      headers: {
        'x-access-token': token
      },
      data: data,

    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.log(objectRes.data);
        // alert(objectRes.data)
        setUsers(objectRes.data);
        console.log(users[2].id);
        // config.setdata();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function DeleteUser(id) {
    const token = localStorage.getItem("token_store_vplay");
    var data = '';

    var config1 = {
      method: 'post',
      status: 1,
      url: `${process.env.REACT_APP_API_URL_NODE}/api/users/notification-delete`,
      headers: {
        'x-access-token': token
      },
      data: { id: id },
    };

    axios(config1)
      .then(function (response) {
        console.log(response);
        if (response.data.message == 'Notifiaction Delete') {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Notifiaction Delete Successfully!</strong>,
            icon: 'success',
            timer: 1500
          })
        }

      })
      .catch(function (error) {
        console.log(error);
      });



  }




  return (
    <>
      <div className="leftContainer">
        <div>

          <div className="main-area">
          {users > '' ?
            users && users.map((user) => (

              (nd ?
                <div className="d-flex  py-3 list-item">
                  <picture className="mr-3">
                    <img height="36px" width="36px" src={right} alt="" />
                    </picture>
                  <div style={{ width: '70%' }}>
                    <span className="font-9 fw-800" style={{ width: '180px', wordWrap: 'break-word' }}>{user.title}</span><div className="" style={{ width: '200px', wordWrap: 'break-word' }}>{user.message}</div><small className="d-flex font-7" style={{ color: 'rgb(199, 199, 199)' }}>{user.date} {user.time}</small></div>
                  {/* <div className="mr-3 btn btn-success btn-sm" onClick={() => DeleteUser(user.id)} style={{ height: '30px', marginLeft: '10px' }} >OK</div> */}
                </div>
                : ""
              )

            ))
            :
            <div className="nodata "><img width="50%" className=" " src={nogame} alt="" />
              <h2 className="text-center" style={{ color: "#feb946" }}>No notification yet!</h2>
              <p className="text-center" style={{ color: "#000" }}>Seems like you haven’t done any activity yet.</p>
            </div>
          }
          </div>

        </div>
      </div>
    </>
  );
}

export default Notification;