
import { React, useState,useEffect } from "react";
import axios from "axios";
import logos from '../images/kkhelchampian.png'
import logo1 from '../images/game-image.jpg'
import logo2 from '../images/snakeandladder.jpg'
import logo3 from '../images/global-battleIconWhiteStroke.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Outlet, Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import logo from '../images/kkhelchampian.png';

import Term from "./Term";
import Rightsidebar from "./Rightsidebar";
function Newdashbord() {
    const [genralsettings, setgenralsetting] = useState([]);
    const [Game1, setGame1] = useState([]);
    const [Game2, setGame2] = useState([]);
    const [Game3, setGame3] = useState([]);
    const [Game4, setGame4] = useState([]);
    useEffect(
        () => {
          genralsetting();
        }, [true]
      );
      const genralsetting = async () => {
        const token = localStorage.getItem("token_store_vplay");
        var config = {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL_NODE}/api/users/get-genral-setting`,
          headers: {
            'x-access-token': token
          },
        };
        
        axios(config)
          .then(function (response) {
            const res = JSON.stringsify(response.data);
            const objectRes = JSON.parse(res);
            setgenralsetting(objectRes.data[0]);
            setGame1(objectRes.game1);
            setGame2(objectRes.game2);
            setGame3(objectRes.game3);
            setGame4(objectRes.game4);
    
          })
          .catch(function (error) {
            console.log(error);
          });
    
      }
    return (
        <>

            <div class="leftContainer">
            <div className="headerContainer">
                    <Link to='/Newdashbord'>
                        <picture className="ml-2 navLogo d-flex">
                            <img src={logo} alt="logo" style={{ height: '55px', width: '70px' }} />
                        </picture>
                    </Link>
                    <div className="menu-items">
                        <Link type="button" className="login-btn" to="/login">LOGIN</Link>
                    </div>
                    <span className="mx-5" />
                </div>  
                <div className="main-area" style={{ paddingTop: '60px' }}>
                    <div className="collapseCard-container">
                    <Marquee>
                        {genralsettings.top_heading}
                        </Marquee>
                        <div className="collapseCard">
                            <h5>khel champion</h5>
                        </div>
                    </div>
                </div>

                <section className="games-section p-3">
                    <div className="d-flex align-items-center games-section-title">Our Games</div>
                    <div className="games-section-headline mt-2 mb-1">
                        <div className="games-window">
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game1 == 1 ? 
                                <Link className="gameCard" to="/Term">
                 
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Classic </div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/Term">
                 
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo1} alt="" />
                                </picture>
                                <div className="gameCard-title">Ludo Classic     </div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game2 == 1 ? 
                                <Link className="gameCard" to="/Term">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo1} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Ludo Classic popular </div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/Term">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo1} alt="" />
                                </picture>
                                <div className="gameCard-title">Ludo Classic popular  </div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                            }
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game3 == 1 ? 
                                <Link className="gameCard" to="/Term">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder </div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/Term">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo2} alt="" />
                                </picture>
                                <div className="gameCard-title">Snake and Ladder </div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div>
                            <div className="gameCard-container">
                                <span className="blink text-danger d-block text-right">◉ LIVE</span>
                                {Game4 == 1 ? 
                                <Link className="gameCard" to="/Term">
                                    <picture className="gameCard-image">
                                        <img width="100%" src={logo2} alt="" />
                                    </picture>
                                    <div className="gameCard-title">Snake and Ladder </div>
                                    <picture className="gameCard-icon">
                                        <img src={logo3} alt="" />
                                    </picture>
                                </Link>
                                :
                                <Link className="gameCard" to="/Term">
                                <picture className="gameCard-image">
                                    <img width="100%" src={logo2} alt="" />
                                </picture>
                                <div className="gameCard-title">Snake and Ladder </div>
                                <picture className="gameCard-icon">
                                    <img src={logo3} alt="" />
                                </picture>
                            </Link>
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className="footer">
                    <div className="footer-divider" />

                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"

                                >
                                    <Typography>

                                        <a className="px-3 py-4 d-block" href="#!" style={{ textDecoration: 'none' }}>
                                            <picture className>

                                                <img src={logos} alt="" width="100px" hight="20px" />
                                            </picture>
                                            {/* <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}> . Terms, Privacy,
                                                Support</span><br />

                                            <span style={{ color: 'rgb(149, 149, 149)', fontSize: '0.8em', fontWeight: 400 }}><strong>The Game is
                                                ON</strong></span> */}
                                        </a>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                       
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                      
                    </div></section>

                 <Rightsidebar/> 
            </div>


            <Outlet />
        </>

    );
}

export default Newdashbord;