import React from "react";
import { Outlet, Link } from "react-router-dom";
import trophybettle from '../images/trophy_image_bettle.png'

const Rules = () => {
  const ludoname = "Badshah Ludo";

  return (
    <>
      <div className="leftContainer">
        <div className="main-area">
          <div className="m-3 py-5 pt-0">
            {/* <div className="d-flex align-items-center rule_ludo_total">
              <h1 className="games-section-title p-0 gamerule font-size-15">Ludo Rules</h1>
            </div>
            <nav aria-label="breadcrumb"><ol class="breadcrumb"><li class="breadcrumb-item"><a href="">Home</a></li><li class="breadcrumb-item active" aria-current="page">Ludo Rules</li></ol></nav> */}
            <h4 className="heading_design"><strong>Game Rules:</strong></h4>
            <div className="">

              
                  <ul className="rules-list">
                    <li className="dashboard_paragraph">यदि दोनों प्लेयर्स ने कोड Join करके गेम प्ले कर लिया हो और दूसरा प्लेयर गोटी ओपन होने के बाद लेफ्ट मरता है तो Opponent प्लेयर पूरा Lose दिया जायेगा.</li>

                    <li className="dashboard_paragraph">यदि दोनों Players गेम प्ले के लिए स्टार्ट करते है एक प्लेयर गेम मे चला गया बल्कि दूसरा प्लेयर गेम मैं नही गया और गेम प्ले हो गया दोनों तरफ से एक प्लेयर की गोटी ओपन हो गई और दूसरा प्लेयर ऑटो Exit है जो ऑटो एग्जिट प्लेयर है वो पूरा लूज़ दिया जायेगा अतः अपना नेट प्रॉब्लम चेक करके खेले ये स्वयं की जिम्मेदारी होगी.</li>
                    <li className="dashboard_paragraph">Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !</li>
                    <li className="dashboard_paragraph">Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !</li>

                    <li className="dashboard_paragraph">दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा] 'कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा.</li>
                    <li className="dashboard_paragraph">कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे अन्यथा वो कैंसिल ही माना जायेगा</li>

                    <li className="dashboard_paragraph"> एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर</li>
                    <li className="dashboard_paragraph">Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं ? गेम का रिकॉड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रका तो नो फ्रेश आईडी ही टेक करे जिनका इंटरेस्ट हो वही टेक करे टेबल को ( इसका अंतिम रिजल्ट एडमिन देगा )</li>
                    <li className="dashboard_paragraph">If you don't have any proof against player cheating and error in the game, then you will be considered as lost for a particular battle.</li>

                    <li className="dashboard_paragraph">When an automatic exit or left slot is opened in a game, the admin will clear the game at his or her discretion. Thank you 🙏.</li>
                    <li className="dashboard_paragraph"> OTP किसी को ना दे ॥ हमारी टीम किसी से किसी भी काम के लिये OTP नहीं लेती है। यदि आप किसी को OTP देते है तो उसके ज़िम्मेदार आप स्वयं होंगे।</li>

                  </ul>

            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Rules;