import React from "react";
import { Outlet, Link } from "react-router-dom";
const Refundpolicy = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area" >
          <div className="m-3">
             {/*  <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></li> 
                <li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
              </ol>
            </nav>*/}
            <div className="row">
            <div className="col-md-12">
                <h3 className="heading_design text-start">Return and refund policy </h3>
              </div>
              <div className="col-12">

                  <p className="dashboard_paragraph text-dark" > 
A Return and Refund Policy is a policy that dictates under what conditions customers can return products they've purchased from your ecommerce store and whether you'll reimb
urse them or not. It will also let your customers know the time frame within which your company can accept returns. 
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Refundpolicy;