
import React from "react";
import { Outlet, Link } from "react-router-dom";
const Contactus = () => {
  return (
    <>

      <div className="leftContainer">
        <div className="main-area"  >
          <div className="m-3">
            <h2  className="heading_design">Contact Us</h2>
          
            <div className="row">
              <div className="col-12">
                {/* <h4>Contact Us:</h4> */}
                <ol className="rules-list" style={{paddingLeft:"0px"}}>
                  For any kind of queries, please contact us on the below mention details<br /><br />
                  <fieldset>
                     <legend className="heading_design">Contact Details</legend>
                     <ul>
                    <li>Badshahludo@outlook.com</li>
                    <li>Contact No. 8690017065
                                               </li>
                 

                                              
                  </ul>
                  </fieldset>
                 
                  {/* <h5>Operational Address:</h5> */}
                  {/* Demo */}
                  {/* <h5>To submit your game write to us:</h5> */}
                  {/* <a href="infi@khelchampion.com">khelchampion@outlook.com</a> */}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contactus;